import React from 'react';

const EventOverview: React.FC = () => {
  return (
    <div className="event-overview">
      <h1 className="text-4xl font-bold mb-6">Music City Rodeo: Nashville's Premier Rodeo Event</h1>
      
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">A 3-Day Rodeo Extravaganza</h2>
        <p className="mb-4">
          For the first time ever, Nashville is bringing the excitement and tradition of professional rodeo to Music City. 
          The Music City Rodeo is set to be a 3-day spectacular event, combining the best of rodeo competition with Nashville's renowned music scene.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Event Highlights</h2>
        <ul className="list-disc pl-5 space-y-2">
          <li><strong>Professional Rodeo Competition:</strong> Featuring top PRCA athletes competing in various rodeo events.</li>
          <li><strong>Live Country Music Performances:</strong> Nightly concerts by chart-topping country music artists.</li>
          <li><strong>Nashville Flavor:</strong> Local food vendors, country-western marketplace, and more.</li>
          <li><strong>Family-Friendly Entertainment:</strong> Activities and shows for all ages.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Daily Schedule Overview</h2>
        <div className="space-y-4">
          <div>
            <h3 className="text-xl font-semibold">Day 1: Opening Day Spectacular</h3>
            <ul className="list-disc pl-5">
              <li>Afternoon: Grand opening ceremony and parade</li>
              <li>Evening: First round of rodeo competitions</li>
              <li>Night: Headline country music performance</li>
            </ul>
          </div>
          <div>
            <h3 className="text-xl font-semibold">Day 2: Rodeo in Full Swing</h3>
            <ul className="list-disc pl-5">
              <li>Morning: Youth rodeo events and clinics</li>
              <li>Afternoon: Main rodeo competitions continue</li>
              <li>Evening: Special Nashville-themed rodeo showcase</li>
              <li>Night: Country music concert featuring multiple artists</li>
            </ul>
          </div>
          <div>
            <h3 className="text-xl font-semibold">Day 3: Championship Finals</h3>
            <ul className="list-disc pl-5">
              <li>Morning: Cowboy church and community events</li>
              <li>Afternoon: Final rounds of rodeo competitions</li>
              <li>Evening: Championship ceremonies and awards</li>
              <li>Night: Closing concert and celebration</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Why Music City Rodeo?</h2>
        <p className="mb-4">
          The Music City Rodeo aims to bring the spirit of the West to the heart of country music. 
          By combining world-class rodeo competition with Nashville's musical heritage, we're creating a unique event that celebrates 
          American traditions and showcases the best of both worlds.
        </p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mb-4">Get Ready for the Ride</h2>
        <p>
          Mark your calendars and prepare for an unforgettable experience as Nashville hosts its first major rodeo event. 
          Whether you're a die-hard rodeo fan or new to the sport, the Music City Rodeo promises three days of thrilling 
          competition, incredible music, and pure Nashville entertainment.
        </p>
      </section>
    </div>
  );
};

export default EventOverview;
