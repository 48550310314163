import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, Pie, Doughnut, Line } from 'react-chartjs-2';
import { motion } from 'framer-motion';
import { FaUsers, FaChartLine, FaPercentage } from 'react-icons/fa';
import {
  ageData,
  genderData,
  incomeData,
  attendanceFrequencyData,
  socialMediaUsageData,
  musicPreferenceData,
} from '../../data/audienceData';
import { chartOptions } from '../../data/chartOptions';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const AudienceOverview: React.FC = () => {
  return (
    <div className="audience-overview">
      <h1 className="text-3xl font-bold mb-6">Audience Overview</h1>
      
      {/* Summary Section */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
        <motion.div className="bg-white p-6 rounded-xl shadow-md" whileHover={{ scale: 1.05 }}>
          <FaUsers size="3em" className="text-blue-500 mb-4" />
          <h3 className="text-xl font-semibold mb-2">Total Audience</h3>
          <p className="text-3xl font-bold">100,000+</p>
        </motion.div>
        <motion.div className="bg-white p-6 rounded-xl shadow-md" whileHover={{ scale: 1.05 }}>
          <FaChartLine size="3em" className="text-green-500 mb-4" />
          <h3 className="text-xl font-semibold mb-2">Growth Rate</h3>
          <p className="text-3xl font-bold">15% YoY</p>
        </motion.div>
        <motion.div className="bg-white p-6 rounded-xl shadow-md" whileHover={{ scale: 1.05 }}>
          <FaPercentage size="3em" className="text-purple-500 mb-4" />
          <h3 className="text-xl font-semibold mb-2">Engagement Rate</h3>
          <p className="text-3xl font-bold">78%</p>
        </motion.div>
      </div>

      {/* Charts Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Age Distribution - Full Width */}
        <motion.div className="bg-white p-6 rounded-xl shadow-md md:col-span-2" whileHover={{ scale: 1.02 }}>
          <h2 className="text-2xl font-semibold mb-4">Age Distribution</h2>
          <div style={{ height: '400px' }}>
            <Bar options={chartOptions.bar} data={ageData} />
          </div>
        </motion.div>
        
        {/* Gender Distribution */}
        <motion.div className="bg-white p-6 rounded-xl shadow-md" whileHover={{ scale: 1.05 }}>
          <h2 className="text-2xl font-semibold mb-4">Gender Distribution</h2>
          <div style={{ height: '300px' }}>
            <Pie options={chartOptions.pie} data={genderData} />
          </div>
        </motion.div>
        
        {/* Income Levels */}
        <motion.div className="bg-white p-6 rounded-xl shadow-md" whileHover={{ scale: 1.05 }}>
          <h2 className="text-2xl font-semibold mb-4">Income Levels</h2>
          <div style={{ height: '300px' }}>
            <Bar options={chartOptions.bar} data={incomeData} />
          </div>
        </motion.div>
        
        {/* Attendance Frequency - Full Width */}
        <motion.div className="bg-white p-6 rounded-xl shadow-md md:col-span-2" whileHover={{ scale: 1.02 }}>
          <h2 className="text-2xl font-semibold mb-4">Attendance Frequency</h2>
          <div style={{ height: '400px' }}>
            <Line options={chartOptions.line} data={attendanceFrequencyData} />
          </div>
        </motion.div>
        
        {/* Social Media Usage */}
        <motion.div className="bg-white p-6 rounded-xl shadow-md" whileHover={{ scale: 1.05 }}>
          <h2 className="text-2xl font-semibold mb-4">Social Media Usage</h2>
          <div style={{ height: '300px' }}>
            <Bar options={chartOptions.bar} data={socialMediaUsageData} />
          </div>
        </motion.div>
        
        {/* Music Preferences */}
        <motion.div className="bg-white p-6 rounded-xl shadow-md" whileHover={{ scale: 1.05 }}>
          <h2 className="text-2xl font-semibold mb-4">Music Preferences</h2>
          <div style={{ height: '300px' }}>
            <Doughnut options={chartOptions.doughnut} data={musicPreferenceData} />
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default AudienceOverview;