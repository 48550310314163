import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaHome, FaCalendarAlt, FaUsers, FaChartBar, FaClipboardList, FaTools, FaMoneyBillWave, FaHandshake, FaFileAlt, FaGavel, FaDatabase, FaChevronDown, FaChevronRight, FaTimes, FaHorse, FaChartLine } from 'react-icons/fa';
import { IconType } from 'react-icons';

interface NavItem {
  name: string;
  path: string;
  icon: IconType;
  children?: NavItem[];
}

interface SidebarProps {
  isOpen: boolean;
  toggleSidebar: () => void;
  isMobile: boolean;
}

const navItems: NavItem[] = [
  { name: 'Home', path: '/', icon: FaHome },
  {
    name: 'Event Overview',
    path: '/event-overview',
    icon: FaCalendarAlt,
    children: [
      { name: 'Event Vision', path: '/vision', icon: FaCalendarAlt },
      { name: 'About Nashville', path: '/about-nashville', icon: FaCalendarAlt },
      { name: 'About Rodeo', path: '/about-rodeo', icon: FaHorse },
      { name: 'Rodeo History', path: '/rodeo-history', icon: FaCalendarAlt },
      { name: 'Rodeo and Music', path: '/rodeo-and-music', icon: FaCalendarAlt },
      { name: 'Growth Plan', path: '/growth-plan', icon: FaCalendarAlt },
    ]
  },
  {
    name: 'Audience Insights',
    path: '/audience-insights',
    icon: FaUsers,
    children: [
      { name: 'Demographics', path: '/demographics', icon: FaUsers },
      { name: 'Behavior & Engagement', path: '/behavior-engagement', icon: FaChartBar },
      { name: 'Psychographics', path: '/psychographics', icon: FaUsers },
      { name: 'Personas', path: '/personas', icon: FaUsers },
      { name: 'Propensity Model', path: '/propensity-model', icon: FaChartBar },
      { name: 'Audience Strategy', path: '/audience-strategy', icon: FaUsers },
    ]
  },
  { 
    name: 'Event Planning', 
    path: '/event-planning', 
    icon: FaClipboardList,
    children: [
      { name: 'Marketing Plan', path: '/marketing-plan', icon: FaClipboardList },
      { name: 'Sponsorship Strategy', path: '/sponsorship-strategy', icon: FaHandshake },
      { name: 'Partnerships', path: '/partnerships', icon: FaHandshake },
      { name: 'Vendors', path: '/vendors', icon: FaClipboardList },
      { name: 'Ticketing Strategy', path: '/ticketing-strategy', icon: FaClipboardList },
      { name: 'Project Timeline', path: '/project-timeline', icon: FaCalendarAlt },
    ]
  },
  { 
    name: 'Event Logistics', 
    path: '/event-logistics', 
    icon: FaTools,
    children: [
      { name: 'Run of Show', path: '/run-of-show', icon: FaClipboardList },
      { name: 'Production Overview', path: '/production-overview', icon: FaTools },
      { name: 'VIP Experiences', path: '/vip-experiences', icon: FaUsers },
      { name: 'Animal Welfare', path: '/animal-welfare', icon: FaHorse },
      { name: 'Security & Emergency Planning', path: '/security-emergency-planning', icon: FaTools },
      { name: 'Catering & Hospitality', path: '/catering-hospitality', icon: FaTools },
    ]
  },
  { 
    name: 'Investment & Financials', 
    path: '/investment-financials', 
    icon: FaMoneyBillWave,
    children: [
      { name: 'Competitive Analysis', path: '/competitive-analysis', icon: FaChartLine },
    ]
  },
  { name: 'Community Partnerships', path: '/community-partnerships', icon: FaHandshake },
  { name: 'Post Event', path: '/post-event', icon: FaFileAlt },
  { name: 'Legal & Administration', path: '/legal-administration', icon: FaGavel },
  { name: 'Shared Resources', path: '/shared-resources', icon: FaDatabase },
];

const Sidebar: React.FC<SidebarProps> = ({ isOpen, toggleSidebar, isMobile }) => {
  const location = useLocation();
  const [openMenus, setOpenMenus] = useState<string[]>([]);

  const isActive = (path: string) => location.pathname.startsWith(path);

  const toggleMenu = (path: string) => {
    setOpenMenus(prevOpenMenus =>
      prevOpenMenus.includes(path)
        ? prevOpenMenus.filter(item => item !== path)
        : [...prevOpenMenus, path]
    );
  };

  const renderNavItems = (items: NavItem[], level = 0) => (
    <ul className={`space-y-2 ${level > 0 ? 'ml-4' : ''}`}>
      {items.map((item) => (
        <li key={item.path}>
          <div className="flex items-center">
            <Link
              to={item.path}
              className={`flex-grow flex items-center p-2 text-base font-normal rounded-lg ${
                isActive(item.path)
                  ? 'text-white bg-indigo-700'
                  : 'text-gray-300 hover:bg-indigo-700'
              }`}
              onClick={() => {
                if (item.children) {
                  toggleMenu(item.path);
                }
              }}
            >
              <item.icon className="w-6 h-6" />
              <span className="ml-3">{item.name}</span>
            </Link>
            {item.children && (
              <button
                onClick={() => toggleMenu(item.path)}
                className="p-2 text-gray-300 hover:bg-indigo-700 rounded-lg"
              >
                {openMenus.includes(item.path) ? (
                  <FaChevronDown className="w-5 h-5" />
                ) : (
                  <FaChevronRight className="w-5 h-5" />
                )}
              </button>
            )}
          </div>
          {item.children && openMenus.includes(item.path) && renderNavItems(item.children, level + 1)}
        </li>
      ))}
    </ul>
  );

  return (
    <>
      <aside
        className={`fixed top-0 left-0 z-40 w-64 h-screen transition-transform ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        } bg-gray-800 text-white shadow-xl`}
      >
        <div className="flex items-center justify-between h-20 bg-indigo-600 px-4">
          <Link to="/" className="text-2xl font-bold">MCR</Link>
          {isMobile && (
            <button
              onClick={toggleSidebar}
              className="p-2 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-white"
            >
              <FaTimes className="w-6 h-6" />
            </button>
          )}
        </div>
        <nav className="h-full px-3 py-4 overflow-y-auto">
          {renderNavItems(navItems)}
        </nav>
      </aside>
    </>
  );
};

export default Sidebar;
