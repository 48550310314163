import React from 'react';
import { FaHorse, FaMusic, FaChartLine, FaCalendarAlt } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const data = [
  { year: 2025, attendance: 30000 },
  { year: 2026, attendance: 50000 },
  { year: 2027, attendance: 100000 },
  { year: 2028, attendance: 150000 },
];

const Home: React.FC = () => {
  return (
    <div className="home-container">
      <motion.h1 
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="main-title"
      >
        Nashville's Next Big Thing: Music City Rodeo
      </motion.h1>
      
      <div className="hero-section">
        <img src="https://via.placeholder.com/1200x400" alt="Music City Rodeo" className="hero-image" />
        <motion.div 
          className="hero-text"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <h2>Where Rodeo Meets Rhythm</h2>
          <p>Coming 2025: An unforgettable fusion of country music and thrilling rodeo action</p>
        </motion.div>
      </div>

      <h2 className="section-title">Why Nashville, Why Now</h2>
      
      <motion.div 
        className="feature-grid"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.4 }}
      >
        <div className="feature-card">
          <FaHorse className="feature-icon" />
          <h3>Rodeo Rising</h3>
          <p>Witness the electrifying comeback of rodeo as a premier sport</p>
        </div>
        <div className="feature-card">
          <FaMusic className="feature-icon" />
          <h3>Music Capital</h3>
          <p>Nashville: Where country music legends are born and celebrated</p>
        </div>
        <div className="feature-card">
          <FaChartLine className="feature-icon" />
          <h3>Economic Powerhouse</h3>
          <p>Boost Nashville's economy with a world-class annual event</p>
        </div>
        <div className="feature-card">
          <FaCalendarAlt className="feature-icon" />
          <h3>Perfect Timing</h3>
          <p>Align with Nashville's growth and the new stadium opening in 2027</p>
        </div>
      </motion.div>

      <motion.div 
        className="vision-section"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.6 }}
      >
        <h2 className="section-title">The Vision</h2>
        <p>
          Music City Rodeo will redefine Nashville's entertainment landscape. Starting in 2025 at the Bridgestone Arena, 
          we'll grow into an outdoor extravaganza by 2027, culminating in a multi-week spectacle at the new stadium in 2028.
        </p>
        <p>
          This unprecedented blend of rodeo excitement and country music star power will not only enthrall audiences 
          but also cement Nashville's status as a world-class entertainment hub, driving substantial economic growth.
        </p>
      </motion.div>

      <div className="graph-section">
        <h2 className="section-title">Projected Growth</h2>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="year" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="attendance" stroke="#8884d8" activeDot={{ r: 8 }} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default Home;
