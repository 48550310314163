import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { useSpring, animated } from '@react-spring/web';
import { FaTicketAlt, FaUsers, FaChartLine, FaCalendarAlt, FaHandshake, FaMusic, FaHorse } from 'react-icons/fa';

const AnimatedSection: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const props = useSpring({
    opacity: 1,
    from: { opacity: 0 },
  });

  return <animated.div style={props}>{children}</animated.div>;
};

const ComprehensiveTicketingStrategy: React.FC = () => {
  return (
    <div className="comprehensive-ticketing-strategy">
      <h1 className="text-4xl font-bold mb-8 text-center text-gray-800">Comprehensive Ticketing Strategy</h1>
      
      <AnimatedSection>
        <section className="mb-12 bg-gradient-to-r from-blue-500 to-indigo-600 text-white p-8 rounded-lg shadow-xl">
          <h2 className="text-3xl font-semibold mb-4">Ticket Strategy Overview</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="flex flex-col items-center">
              <FaTicketAlt className="text-5xl mb-4" />
              <h3 className="text-xl font-semibold mb-2">Capacity</h3>
              <p className="text-center">16,000 seats per night (3 nights)</p>
            </div>
            <div className="flex flex-col items-center">
              <FaUsers className="text-5xl mb-4" />
              <h3 className="text-xl font-semibold mb-2">Audience Segments</h3>
              <ul className="list-disc pl-5">
                <li>General Admission</li>
                <li>VIP Packages</li>
                <li>Family & Group</li>
                <li>Weekend Passes</li>
              </ul>
            </div>
            <div className="flex flex-col items-center">
              <FaChartLine className="text-5xl mb-4" />
              <h3 className="text-xl font-semibold mb-2">Pricing Strategy</h3>
              <ul className="list-disc pl-5">
                <li>Dynamic Pricing</li>
                <li>Early Bird Discounts</li>
                <li>Tiered Pricing</li>
              </ul>
            </div>
          </div>
        </section>
      </AnimatedSection>

      <AnimatedSection>
        <section className="mb-12">
          <h2 className="text-3xl font-semibold mb-6 text-center text-gray-800">Ticket Breakdown & Pricing Model</h2>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-300 shadow-lg rounded-lg overflow-hidden">
              <thead className="bg-gray-800 text-white">
                <tr>
                  <th className="py-3 px-4 text-left">Ticket Type</th>
                  <th className="py-3 px-4 text-left">Seats/Night</th>
                  <th className="py-3 px-4 text-left">Price/Unit</th>
                  <th className="py-3 px-4 text-left">Total Revenue/Night</th>
                  <th className="py-3 px-4 text-left">3-Night Revenue</th>
                </tr>
              </thead>
              <tbody>
                <tr className="bg-gray-100">
                  <td className="py-3 px-4">GA Early Bird</td>
                  <td className="py-3 px-4">2,500</td>
                  <td className="py-3 px-4">$75</td>
                  <td className="py-3 px-4">$187,500</td>
                  <td className="py-3 px-4">$562,500</td>
                </tr>
                <tr>
                  <td className="py-3 px-4">GA Standard</td>
                  <td className="py-3 px-4">8,500</td>
                  <td className="py-3 px-4">$100</td>
                  <td className="py-3 px-4">$850,000</td>
                  <td className="py-3 px-4">$2,550,000</td>
                </tr>
                <tr className="bg-gray-100">
                  <td className="py-3 px-4">VIP Early Bird</td>
                  <td className="py-3 px-4">1,000</td>
                  <td className="py-3 px-4">$250</td>
                  <td className="py-3 px-4">$250,000</td>
                  <td className="py-3 px-4">$750,000</td>
                </tr>
                <tr>
                  <td className="py-3 px-4">VIP Standard</td>
                  <td className="py-3 px-4">3,000</td>
                  <td className="py-3 px-4">$400</td>
                  <td className="py-3 px-4">$1,200,000</td>
                  <td className="py-3 px-4">$3,600,000</td>
                </tr>
                <tr className="bg-gray-100">
                  <td className="py-3 px-4">Family/Group (Discount)</td>
                  <td className="py-3 px-4">500</td>
                  <td className="py-3 px-4">$85</td>
                  <td className="py-3 px-4">$42,500</td>
                  <td className="py-3 px-4">$127,500</td>
                </tr>
                <tr>
                  <td className="py-3 px-4">Weekend Pass (GA)</td>
                  <td className="py-3 px-4">700 (total)</td>
                  <td className="py-3 px-4">$250</td>
                  <td className="py-3 px-4">N/A</td>
                  <td className="py-3 px-4">$175,000 (total)</td>
                </tr>
                <tr className="bg-gray-100">
                  <td className="py-3 px-4">VIP Weekend Pass</td>
                  <td className="py-3 px-4">300 (total)</td>
                  <td className="py-3 px-4">$750</td>
                  <td className="py-3 px-4">N/A</td>
                  <td className="py-3 px-4">$225,000 (total)</td>
                </tr>
                <tr className="bg-gray-200 font-bold">
                  <td className="py-3 px-4">Total Revenue</td>
                  <td className="py-3 px-4">16,000</td>
                  <td className="py-3 px-4">N/A</td>
                  <td className="py-3 px-4">$2,530,000</td>
                  <td className="py-3 px-4">$8,365,000</td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
      </AnimatedSection>

      <AnimatedSection>
        <section className="mb-12">
          <h2 className="text-3xl font-semibold mb-6 text-center text-gray-800">Additional Revenue Streams</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="bg-white p-6 rounded-lg shadow-lg border border-gray-200">
              <FaHandshake className="text-4xl mb-4 text-blue-600" />
              <h3 className="text-xl font-semibold mb-2">Meet-and-Greet Packages</h3>
              <p>Exclusive access to artists and rodeo stars. Price: $500-$750 per ticket.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg border border-gray-200">
              <FaMusic className="text-4xl mb-4 text-blue-600" />
              <h3 className="text-xl font-semibold mb-2">Branded Merchandise</h3>
              <p>Exclusive Music City Rodeo gear and collaborations with local brands.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg border border-gray-200">
              <FaHorse className="text-4xl mb-4 text-blue-600" />
              <h3 className="text-xl font-semibold mb-2">Rodeo Experiences</h3>
              <p>"Cowboy for a Day" and "Ride with the Stars" experiences. Price: $2,500+</p>
            </div>
          </div>
        </section>
      </AnimatedSection>

      <AnimatedSection>
        <section className="mb-12">
          <h2 className="text-3xl font-semibold mb-6 text-center text-gray-800">Timeline for Ticket Sales & Revenue Maximization</h2>
          <VerticalTimeline>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: '#f3f4f6', color: '#1f2937' }}
              contentArrowStyle={{ borderRight: '7px solid  #f3f4f6' }}
              date="December 2024"
              iconStyle={{ background: '#3b82f6', color: '#fff' }}
              icon={<FaCalendarAlt />}
            >
              <h3 className="vertical-timeline-element-title">Pre-Sale & Early Bird Launch</h3>
              <p>
                Early Bird GA: $75 | VIP Early Bird: $250
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: '#f3f4f6', color: '#1f2937' }}
              contentArrowStyle={{ borderRight: '7px solid  #f3f4f6' }}
              date="January 2025"
              iconStyle={{ background: '#3b82f6', color: '#fff' }}
              icon={<FaTicketAlt />}
            >
              <h3 className="vertical-timeline-element-title">Full Sales & VIP Push</h3>
              <p>
                Launch Weekend Passes (GA: $250, VIP: $750)
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: '#f3f4f6', color: '#1f2937' }}
              contentArrowStyle={{ borderRight: '7px solid  #f3f4f6' }}
              date="February 2025"
              iconStyle={{ background: '#3b82f6', color: '#fff' }}
              icon={<FaUsers />}
            >
              <h3 className="vertical-timeline-element-title">Mid-Campaign Rodeo Cross-Promotion</h3>
              <p>
                Cross-promotion with San Antonio Rodeo and Wrangler NFR
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: '#f3f4f6', color: '#1f2937' }}
              contentArrowStyle={{ borderRight: '7px solid  #f3f4f6' }}
              date="March-May 2025"
              iconStyle={{ background: '#3b82f6', color: '#fff' }}
              icon={<FaChartLine />}
            >
              <h3 className="vertical-timeline-element-title">Final Push & Last-Minute Sales</h3>
              <p>
                Ramp up digital ads and local partnerships
              </p>
            </VerticalTimelineElement>
          </VerticalTimeline>
        </section>
      </AnimatedSection>

      <AnimatedSection>
        <section className="mb-12">
          <h2 className="text-3xl font-semibold mb-6 text-center text-gray-800">Artist Meet-and-Greet Strategy</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="bg-blue-100 p-6 rounded-lg shadow-lg border border-blue-200">
              <h3 className="text-2xl font-semibold mb-2 text-blue-800">Tier 1: A-List Artists</h3>
              <p className="mb-2 text-blue-600">Price: $2,500 - $5,000</p>
              <p className="text-gray-700">Personalized photo, signed merchandise, VIP access</p>
            </div>
            <div className="bg-indigo-100 p-6 rounded-lg shadow-lg border border-indigo-200">
              <h3 className="text-2xl font-semibold mb-2 text-indigo-800">Tier 2: Mid-Level Artists</h3>
              <p className="mb-2 text-indigo-600">Price: $1,000 - $2,500</p>
              <p className="text-gray-700">Group photo, signed memorabilia, VIP lounge access</p>
            </div>
            <div className="bg-purple-100 p-6 rounded-lg shadow-lg border border-purple-200">
              <h3 className="text-2xl font-semibold mb-2 text-purple-800">Tier 3: Rising Artists</h3>
              <p className="mb-2 text-purple-600">Price: $500 - $1,000</p>
              <p className="text-gray-700">Group photo, signed poster, general VIP access</p>
            </div>
          </div>
        </section>
      </AnimatedSection>
    </div>
  );
};

export default ComprehensiveTicketingStrategy;