import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

interface Event {
  name: string;
  duration: string;
  time: string;
  notes: string;
  type: string;
}

const eventData: { [key: string]: Event[] } = {
  Thursday: [
    { name: "Intro video dark house", duration: "0:01:00", time: "6:30:00 PM", notes: "Build up Intro", type: "Pre-Show" },
    { name: "Light and Pyro Show", duration: "0:01:00", time: "", notes: "", type: "Pre-Show" },
    { name: "Intro live song", duration: "0:03:00", time: "6:35:00 PM", notes: "Kick-off Song", type: "Event" },
    { name: "Grand entry", duration: "", time: "", notes: "", type: "Event" },
    { name: "Flag Girls loop arena twice", duration: "0:02:00", time: "", notes: "21 flags looped on the arena at trot", type: "Event" },
    { name: "Introduce Miss Music City Rodeo", duration: "0:00:33", time: "", notes: "", type: "Event" },
    { name: "Visiting Queens", duration: "0:00:22", time: "", notes: "", type: "Event" },
    { name: "President introduction", duration: "0:01:00", time: "", notes: "", type: "Event" },
    { name: "Stock Contractor introduction", duration: "0:00:33", time: "", notes: "", type: "Event" },
    { name: "Pick-up Men introduction", duration: "0:00:33", time: "", notes: "", type: "Event" },
    { name: "Presentation of Colors", duration: "0:01:20", time: "", notes: "", type: "Event" },
    { name: "Cowboy Prayer", duration: "0:02:00", time: "6:43:21 PM", notes: "", type: "Event" },
    { name: "National Anthem Artist Intro", duration: "0:00:33", time: "", notes: "", type: "Event" },
    { name: "National Anthem", duration: "0:01:00", time: "", notes: "", type: "Event" },
    { name: "Explain Teams", duration: "0:01:30", time: "6:46:24 PM", notes: "TnT Charity Teams explanation and Introduction", type: "Event" },
    { name: "RODEO EVENTS START", duration: "", time: "", notes: "", type: "Event" },
    { name: "Sponsor Flag Hot Lap", duration: "0:00:20", time: "", notes: "", type: "Sponsor" },
    { name: "Bareback Riding (12)", duration: "0:11:00", time: "6:57:44 PM", notes: "", type: "Event" },
    { name: "Stage Steer Wrestlers", duration: "0:00:20", time: "6:58:24 PM", notes: "Steer Wrestlers to ride to roping end of arena", type: "Event" },
    { name: "Visiting Queens Intro", duration: "0:00:20", time: "6:58:24 PM", notes: "", type: "Event" },
    { name: "Sponsor Flag Hot Lap", duration: "0:00:20", time: "", notes: "", type: "Sponsor" },
    { name: "Steer Wrestling (10)", duration: "0:10:00", time: "7:08:44 PM", notes: "", type: "Event" },
    { name: "Clown Bit -1", duration: "0:01:40", time: "", notes: "Set up Mutton Bustin chutes/ close roping chute", type: "Event" },
    { name: "Sponsor Hot Lap", duration: "0:00:20", time: "", notes: "", type: "Sponsor" },
    { name: "Mutton Bustin (12)", duration: "0:06:30", time: "7:17:14 PM", notes: "", type: "Event" },
    { name: "Jumbo Tron Advertisment -1", duration: "0:00:30", time: "", notes: "Intermissions - clear Mutton Bustin chutes and sheep", type: "Sponsor" },
    { name: "Sponsor Hot Lap", duration: "0:00:20", time: "", notes: "", type: "Sponsor" },
    { name: "Team Roping (10)", duration: "0:09:30", time: "7:27:34 PM", notes: "", type: "Event" },
    { name: "Arena Switch", duration: "0:00:15", time: "", notes: "Close roping chutes", type: "Event" },
    { name: "Sponsor Hot Lap", duration: "0:00:20", time: "", notes: "", type: "Sponsor" },
    { name: "Saddle Bronc Riding (12)", duration: "0:15:00", time: "7:43:09 PM", notes: "", type: "Event" },
    { name: "Jumbo Tron Advertisement - 2", duration: "0:00:15", time: "", notes: "Forced Intermission - open roping chute", type: "Sponsor" },
    { name: "Clown Bit - 2", duration: "0:01:40", time: "", notes: "", type: "Event" },
    { name: "Sponsor Hot Lap", duration: "0:00:20", time: "", notes: "", type: "Sponsor" },
    { name: "Tie Down Roping (10)", duration: "0:11:00", time: "7:56:24 PM", notes: "", type: "Event" },
    { name: "Jumbo Tron Advertisement - 3 ", duration: "0:00:15", time: "", notes: "Forced intermission", type: "Sponsor" },
    { name: "Pro Am Introduction", duration: "0:00:45", time: "", notes: "", type: "Event" },
    { name: "Pro Am Celebrity Roping", duration: "0:05:00", time: "8:02:24 PM", notes: "", type: "Event" },
    { name: "Arena Drag", duration: "0:03:00", time: "8:05:24 PM", notes: "Song opportunity while arena is drug", type: "Event" },
    { name: "Barrel Set-up", duration: "0:01:00", time: "", notes: "Barrels brought into arena in sponsor truck (FORD)", type: "Event" },
    { name: "Sponsor Hot Lap", duration: "0:00:20", time: "", notes: "", type: "Sponsor" },
    { name: "Barrel Racing First 5", duration: "0:03:00", time: "", notes: "", type: "Event" },
    { name: "Arena Drag", duration: "0:03:00", time: "", notes: "Song opportunity while arena is drug", type: "Event" },
    { name: "Barrel Racing Second 5", duration: "0:03:00", time: "", notes: "", type: "Event" },
    { name: "Celebrity Am Introduction", duration: "0:00:45", time: "", notes: "", type: "Event" },
    { name: "Celebrity Am Barrels", duration: "0:00:30", time: "", notes: "", type: "Event" },
    { name: "Remove Barrels", duration: "0:01:00", time: "8:17:59 PM", notes: "Pick up Barrels sponsor truck (FORD)", type: "Event" },
    { name: "Bull Riding Guitar Solo", duration: "0:00:30", time: "", notes: "", type: "Event" },
    { name: "Sponsor Hot Lap", duration: "0:00:20", time: "", notes: "", type: "Sponsor" },
    { name: "Bull Fighter introduction", duration: "0:00:33", time: "", notes: "", type: "Event" },
    { name: "Bull Riding (14)", duration: "0:21:00", time: "8:40:22 PM", notes: "", type: "Event" },
  ],
  Friday: [
    { name: "Intro video dark house", duration: "0:01:00", time: "6:30:00 PM", notes: "Build up Intro", type: "Pre-Show" },
    { name: "Light and Pyro Show", duration: "0:01:00", time: "", notes: "", type: "Pre-Show" },
    { name: "Intro live song", duration: "0:03:00", time: "6:35:00 PM", notes: "Kick-off Song", type: "Event" },
    { name: "Grand entry", duration: "", time: "", notes: "", type: "Event" },
    { name: "Flag Girls loop arena twice", duration: "0:02:00", time: "", notes: "21 flags looped on the arena at trot", type: "Event" },
    { name: "Introduce Miss Music City Rodeo", duration: "0:00:33", time: "", notes: "", type: "Event" },
    { name: "Visiting Queens", duration: "0:00:22", time: "", notes: "", type: "Event" },
    { name: "President introduction", duration: "0:01:00", time: "", notes: "", type: "Event" },
    { name: "Stock Contractor introduction", duration: "0:00:33", time: "", notes: "", type: "Event" },
    { name: "Pick-up Men introduction", duration: "0:00:33", time: "", notes: "", type: "Event" },
    { name: "Presentation of Colors", duration: "0:01:20", time: "", notes: "", type: "Event" },
    { name: "Cowboy Prayer", duration: "0:02:00", time: "6:43:21 PM", notes: "", type: "Event" },
    { name: "National Anthem Artist Intro", duration: "0:00:33", time: "", notes: "", type: "Event" },
    { name: "National Anthem", duration: "0:01:00", time: "", notes: "", type: "Event" },
    { name: "Explain Teams", duration: "0:01:30", time: "6:46:24 PM", notes: "TnT Charity Teams explanation and Introduction", type: "Event" },
    { name: "RODEO EVENTS START", duration: "", time: "", notes: "", type: "Event" },
    { name: "Sponsor Flag Hot Lap", duration: "0:00:20", time: "", notes: "", type: "Sponsor" },
    { name: "Bareback Riding (12)", duration: "0:11:00", time: "6:57:44 PM", notes: "", type: "Event" },
    { name: "Stage Steer Wrestlers", duration: "0:00:20", time: "", notes: "Steer Wrestlers to ride to roping end of arena", type: "Event" },
    { name: "Visiting Queens Intro", duration: "0:00:20", time: "", notes: "", type: "Event" },
    { name: "Crowning Miss Rodeo TN", duration: "0:01:20", time: "6:59:44 PM", notes: "Crown Miss Rodeo TN 2025", type: "Event" },
    { name: "Sponsor Flag Hot Lap", duration: "0:00:20", time: "", notes: "", type: "Sponsor" },
    { name: "Steer Wrestling (10)", duration: "0:10:00", time: "7:10:04 PM", notes: "", type: "Event" },
    { name: "Clown Bit -1", duration: "0:01:40", time: "", notes: "Set up Mutton Bustin chutes/ close roping chute", type: "Event" },
    { name: "Sponsor Hot Lap", duration: "0:00:20", time: "", notes: "", type: "Sponsor" },
    { name: "Mutton Bustin (12)", duration: "0:06:30", time: "7:18:34 PM", notes: "", type: "Event" },
    { name: "Jumbo Tron Advertisment -1", duration: "0:00:30", time: "", notes: "Intermissions - clear Mutton Bustin chutes and sheep", type: "Sponsor" },
    { name: "Sponsor Hot Lap", duration: "0:00:20", time: "", notes: "", type: "Sponsor" },
    { name: "Team Roping (10)", duration: "0:09:30", time: "7:28:54 PM", notes: "", type: "Event" },
    { name: "Arena Switch", duration: "0:00:15", time: "", notes: "Close roping chutes", type: "Event" },
    { name: "Sponsor Hot Lap", duration: "0:00:20", time: "", notes: "", type: "Sponsor" },
    { name: "Saddle Bronc Riding (12)", duration: "0:15:00", time: "7:44:29 PM", notes: "", type: "Event" },
    { name: "Jumbo Tron Advertisement - 2", duration: "0:00:15", time: "", notes: "Forced Intermission - open roping chute", type: "Sponsor" },
    { name: "Clown Bit - 2", duration: "0:01:40", time: "", notes: "", type: "Event" },
    { name: "Sponsor Hot Lap", duration: "0:00:20", time: "", notes: "", type: "Sponsor" },
    { name: "Tie Down Roping (10)", duration: "0:11:00", time: "7:57:44 PM", notes: "", type: "Event" },
    { name: "Jumbo Tron Advertisement - 3 ", duration: "0:00:15", time: "", notes: "Forced intermission", type: "Sponsor" },
    { name: "Pro Am Introduction", duration: "0:00:45", time: "", notes: "", type: "Event" },
    { name: "Pro Am Celebrity Roping", duration: "0:05:00", time: "8:03:44 PM", notes: "", type: "Event" },
    { name: "Arena Drag", duration: "0:03:00", time: "8:06:44 PM", notes: "Song opportunity while arena is drug", type: "Event" },
    { name: "Barrel Set-up", duration: "0:01:00", time: "", notes: "Barrels brought into arena in sponsor truck (FORD)", type: "Event" },
    { name: "Sponsor Hot Lap", duration: "0:00:20", time: "", notes: "", type: "Sponsor" },
    { name: "Barrel Racing First 5", duration: "0:03:00", time: "", notes: "", type: "Event" },
    { name: "Arena Drag", duration: "0:03:00", time: "", notes: "Song opportunity while arena is drug", type: "Event" },
    { name: "Barrel Racing Second 5", duration: "0:03:00", time: "", notes: "", type: "Event" },
    { name: "Celebrity Am Introduction", duration: "0:00:45", time: "", notes: "", type: "Event" },
    { name: "Celebrity Am Barrels", duration: "0:00:30", time: "", notes: "", type: "Event" },
    { name: "Remove Barrels", duration: "0:01:00", time: "8:19:19 PM", notes: "Pick up Barrels sponsor truck (FORD)", type: "Event" },
    { name: "Bull Riding Guitar Solo", duration: "0:00:30", time: "", notes: "", type: "Event" },
    { name: "Sponsor Hot Lap", duration: "0:00:20", time: "", notes: "", type: "Sponsor" },
    { name: "Bull Fighter introduction", duration: "0:00:33", time: "", notes: "", type: "Event" },
    { name: "Bull Riding (14)", duration: "0:21:00", time: "8:41:42 PM", notes: "", type: "Event" },
  ],
  Saturday: [
    { name: "Intro video dark house", duration: "0:01:00", time: "6:30:00 PM", notes: "Build up Intro", type: "Pre-Show" },
    { name: "Light and Pyro Show", duration: "0:01:00", time: "", notes: "", type: "Pre-Show" },
    { name: "Intro live song", duration: "0:03:00", time: "6:35:00 PM", notes: "Kick-off Song", type: "Event" },
    { name: "Grand entry", duration: "", time: "", notes: "", type: "Event" },
    { name: "Flag Girls loop arena twice", duration: "0:02:00", time: "", notes: "21 flags looped on the arena at trot", type: "Event" },
    { name: "Introduce Miss Music City Rodeo", duration: "0:00:33", time: "", notes: "", type: "Event" },
    { name: "Visiting Queens", duration: "0:00:22", time: "", notes: "", type: "Event" },
    { name: "President introduction", duration: "0:01:00", time: "", notes: "", type: "Event" },
    { name: "Stock Contractor introduction", duration: "0:00:33", time: "", notes: "", type: "Event" },
    { name: "Pick-up Men introduction", duration: "0:00:33", time: "", notes: "", type: "Event" },
    { name: "Presentation of Colors", duration: "0:01:20", time: "", notes: "", type: "Event" },
    { name: "Cowboy Prayer", duration: "0:02:00", time: "6:43:21 PM", notes: "", type: "Event" },
    { name: "National Anthem Artist Intro", duration: "0:00:33", time: "", notes: "", type: "Event" },
    { name: "National Anthem", duration: "0:01:00", time: "", notes: "", type: "Event" },
    { name: "Explain Teams", duration: "0:01:30", time: "6:46:24 PM", notes: "TnT Charity Teams explanation and Introduction", type: "Event" },
    { name: "RODEO EVENTS START", duration: "", time: "", notes: "", type: "Event" },
    { name: "Sponsor Flag Hot Lap", duration: "0:00:20", time: "", notes: "", type: "Sponsor" },
    { name: "Bareback Riding (12)", duration: "0:11:00", time: "6:57:44 PM", notes: "", type: "Event" },
    { name: "Stage Steer Wrestlers", duration: "0:00:20", time: "", notes: "Steer Wrestlers to ride to roping end of arena", type: "Event" },
    { name: "Visiting Queens Intro", duration: "0:00:20", time: "6:58:24 PM", notes: "", type: "Event" },
    { name: "Sponsor Hot Lap", duration: "0:00:20", time: "", notes: "", type: "Sponsor" },
    { name: "Steer Wrestling (10)", duration: "0:10:00", time: "", notes: "", type: "Event" },
    { name: "In Arena Award Presentation", duration: "0:02:00", time: "7:10:44 PM", notes: "Bareback and Steer Wrestling Champions", type: "Event" },
    { name: "Clown Bit -1", duration: "0:01:40", time: "", notes: "Set up Mutton Bustin chutes/ close roping chute", type: "Event" },
    { name: "Sponsor Hot Lap", duration: "0:00:20", time: "", notes: "", type: "Sponsor" },
    { name: "Mutton Bustin (12)", duration: "0:06:30", time: "7:19:14 PM", notes: "", type: "Event" },
    { name: "Jumbo Tron Advertisment -1", duration: "0:00:30", time: "", notes: "Intermissions - clear Mutton Bustin chutes and sheep", type: "Sponsor" },
    { name: "Sponsor Hot Lap", duration: "0:00:20", time: "", notes: "", type: "Sponsor" },
    { name: "Team Roping (10)", duration: "0:09:30", time: "7:29:34 PM", notes: "", type: "Event" },
    { name: "Arena Switch", duration: "0:00:15", time: "", notes: "Close roping chutes", type: "Event" },
    { name: "Sponsor Hot Lap", duration: "0:00:20", time: "", notes: "", type: "Sponsor" },
    { name: "Saddle Bronc Riding (12)", duration: "0:15:00", time: "7:45:09 PM", notes: "", type: "Event" },
    { name: "Jumbo Tron Advertisement - 2", duration: "0:00:15", time: "", notes: "Forced Intermission - open roping chute", type: "Sponsor" },
    { name: "Sponsor Hot Lap", duration: "0:00:20", time: "", notes: "", type: "Sponsor" },
    { name: "Tie Down Roping (10)", duration: "0:11:00", time: "", notes: "", type: "Event" },
    { name: "In Arena Award Presentation", duration: "0:03:00", time: "7:59:44 PM", notes: "Team Roping, Saddle Bronc, Tie Down Roping Champions", type: "Event" },
    { name: "Jumbo Tron Advertisement - 3 ", duration: "0:00:15", time: "", notes: "Forced intermission", type: "Sponsor" },
    { name: "Pro Am Introduction", duration: "0:00:45", time: "", notes: "", type: "Event" },
    { name: "Pro Am Celebrity Roping", duration: "0:05:00", time: "8:05:44 PM", notes: "", type: "Event" },
    { name: "Arena Drag", duration: "0:03:00", time: "8:08:44 PM", notes: "Song opportunity while arena is drug", type: "Event" },
    { name: "Barrel Set-up", duration: "0:01:00", time: "", notes: "Barrels brought into arena in sponsor truck (FORD)", type: "Event" },
    { name: "Sponsor Hot Lap", duration: "0:00:20", time: "", notes: "", type: "Sponsor" },
    { name: "Barrel Racing First 5", duration: "0:03:00", time: "", notes: "", type: "Event" },
    { name: "Arena Drag", duration: "0:03:00", time: "", notes: "Song opportunity while arena is drug", type: "Event" },
    { name: "Barrel Racing Second 5", duration: "0:03:00", time: "", notes: "", type: "Event" },
    { name: "Celebrity Am Introduction", duration: "0:00:45", time: "", notes: "", type: "Event" },
    { name: "Celebrity Am Barrels", duration: "0:00:30", time: "", notes: "", type: "Event" },
    { name: "Remove Barrels", duration: "0:01:00", time: "8:21:19 PM", notes: "Pick up Barrels sponsor truck (FORD)", type: "Event" },
    { name: "Bull Riding Guitar Solo", duration: "0:00:30", time: "", notes: "", type: "Event" },
    { name: "Sponsor Hot Lap", duration: "0:00:20", time: "", notes: "", type: "Sponsor" },
    { name: "Bull Fighter introduction", duration: "0:00:33", time: "", notes: "", type: "Event" },
    { name: "Bull Riding (14)", duration: "0:21:00", time: "", notes: "", type: "Event" },
    { name: "Award Presentation", duration: "0:02:00", time: "8:45:42 PM", notes: "", type: "Event" },
  ],
};

const RunOfShow: React.FC = () => {
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);

  const toggleType = (type: string) => {
    setSelectedTypes(prev => 
      prev.includes(type) ? prev.filter(t => t !== type) : [...prev, type]
    );
  };

  const filterEvents = (events: Event[]) => {
    return selectedTypes.length === 0 ? events : events.filter(event => selectedTypes.includes(event.type));
  };

  const calculateTotalDuration = (events: Event[]) => {
    const totalSeconds = events.reduce((total, event) => {
      const [minutes, seconds] = event.duration.split(':').map(Number);
      return total + minutes * 60 + seconds;
    }, 0);

    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const eventTypes = ['Pre-Show', 'Event', 'Sponsor', 'Gap', 'Concert'];

  return (
    <div className="run-of-show">
      <h1 className="text-3xl font-bold mb-6">Run of Show - May 29, 30, 31</h1>
      <div className="filters mb-6">
        <h2 className="text-xl font-semibold mb-2">Filter by Event Type:</h2>
        {eventTypes.map(type => (
          <button
            key={type}
            onClick={() => toggleType(type)}
            className={`mr-2 mb-2 px-4 py-2 rounded ${
              selectedTypes.includes(type)
                ? 'bg-blue-500 text-white'
                : 'bg-gray-200 text-gray-700'
            }`}
          >
            {type}
          </button>
        ))}
      </div>
      <Tabs>
        <TabList className="flex border-b mb-4">
          {Object.keys(eventData).map(day => (
            <Tab
              key={day}
              className="px-4 py-2 cursor-pointer"
              selectedClassName="border-b-2 border-blue-500 font-semibold"
            >
              {day}
            </Tab>
          ))}
        </TabList>

        {Object.entries(eventData).map(([day, events]) => (
          <TabPanel key={day}>
            <h2 className="text-2xl font-bold mb-4">{day}</h2>
            <p className="mb-4">Total Duration: {calculateTotalDuration(events)}</p>
            <table className="w-full border-collapse">
              <thead>
                <tr className="bg-gray-100">
                  <th className="border px-4 py-2">Event</th>
                  <th className="border px-4 py-2">Duration</th>
                  <th className="border px-4 py-2">Time</th>
                  <th className="border px-4 py-2">Notes</th>
                </tr>
              </thead>
              <tbody>
                {filterEvents(events).map((event, index) => (
                  <tr key={index} className={`${event.type.toLowerCase()} hover:bg-gray-50`}>
                    <td className="border px-4 py-2">{event.name}</td>
                    <td className="border px-4 py-2">{event.duration}</td>
                    <td className="border px-4 py-2">{event.time}</td>
                    <td className="border px-4 py-2">{event.notes}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </TabPanel>
        ))}
      </Tabs>
      <div className="mt-8">
        <h2 className="text-xl font-semibold mb-2">Event Type Legend:</h2>
        <div className="grid grid-cols-2 gap-4">
          {eventTypes.map(type => (
            <div key={type} className={`p-2 rounded ${type.toLowerCase()}`}>
              {type}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RunOfShow;