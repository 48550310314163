// Sample data for audience overview charts

export const ageData = {
  labels: ['18-24', '25-34', '35-44', '45-54', '55-64', '65+'],
  datasets: [
    {
      label: 'Age Distribution',
      data: [15, 25, 20, 18, 12, 10],
      backgroundColor: 'rgba(75, 192, 192, 0.6)',
    },
  ],
};

export const genderData = {
  labels: ['Male', 'Female', 'Other'],
  datasets: [
    {
      data: [48, 50, 2],
      backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
    },
  ],
};

export const incomeData = {
  labels: ['&lt;$30k', '$30k-$50k', '$50k-$75k', '$75k-$100k', '&gt;$100k'],
  datasets: [
    {
      label: 'Income Levels',
      data: [10, 20, 30, 25, 15],
      backgroundColor: 'rgba(153, 102, 255, 0.6)',
    },
  ],
};

export const attendanceFrequencyData = {
  labels: ['First Time', '2-3 Times', '4-5 Times', 'Regular Attendee'],
  datasets: [
    {
      data: [30, 40, 20, 10],
      backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
    },
  ],
};

export const socialMediaUsageData = {
  labels: ['Facebook', 'Instagram', 'Twitter', 'TikTok', 'LinkedIn'],
  datasets: [
    {
      label: 'Social Media Usage',
      data: [70, 80, 50, 60, 30],
      backgroundColor: 'rgba(255, 159, 64, 0.6)',
    },
  ],
};

export const musicPreferenceData = {
  labels: ['Country', 'Rock', 'Pop', 'Hip Hop', 'Electronic', 'Other'],
  datasets: [
    {
      data: [40, 20, 15, 10, 8, 7],
      backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'],
    },
  ],
};