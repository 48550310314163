export interface Task {
  dateRange: string;
  taskCategory: string;
  taskDescription: string;
  ticketsAvailable: string;
  promotionalPush: string;
  dynamicPricing: string;
  status: string;
}

export const initialTasks: Task[] = [
  { dateRange: 'October 2024', taskCategory: 'Investor Effort', taskDescription: 'Establish Investor List', ticketsAvailable: 'N/A', promotionalPush: 'N/A', dynamicPricing: 'N/A', status: 'Not Started' },
  { dateRange: 'October 01, 2024', taskCategory: 'Investor Effort', taskDescription: 'Financial Model Update', ticketsAvailable: 'N/A', promotionalPush: 'N/A', dynamicPricing: 'N/A', status: 'Not Started' },
  { dateRange: 'October 04, 2024', taskCategory: 'Investor Effort', taskDescription: 'Draft Deck for Review', ticketsAvailable: 'N/A', promotionalPush: 'N/A', dynamicPricing: 'N/A', status: 'Not Started' },
  { dateRange: 'October 16, 2024', taskCategory: 'Investor Effort', taskDescription: 'Final Financial Model', ticketsAvailable: 'N/A', promotionalPush: 'N/A', dynamicPricing: 'N/A', status: 'Not Started' },
  { dateRange: 'October 18, 2024', taskCategory: 'Investor Effort', taskDescription: 'Final Deck for Issuance', ticketsAvailable: 'N/A', promotionalPush: 'N/A', dynamicPricing: 'N/A', status: 'Not Started' },
  
  { dateRange: 'November 15, 2024', taskCategory: '501c3', taskDescription: 'Initiated', ticketsAvailable: 'N/A', promotionalPush: 'N/A', dynamicPricing: 'N/A', status: 'In Progress' },
  { dateRange: 'January 01, 2025', taskCategory: '501c3', taskDescription: 'Expected to be accepted by IRS', ticketsAvailable: 'N/A', promotionalPush: 'N/A', dynamicPricing: 'N/A', status: 'Not Started' },

  { dateRange: 'October 15, 2024', taskCategory: 'Venue', taskDescription: 'Bridgestone Contract Final', ticketsAvailable: 'N/A', promotionalPush: 'N/A', dynamicPricing: 'N/A', status: 'Not Started' },

  { dateRange: 'October 2024', taskCategory: 'PRCA and WPRCA', taskDescription: 'PRCA 2025 Application - COMPLETE', ticketsAvailable: 'N/A', promotionalPush: 'N/A', dynamicPricing: 'N/A', status: 'Completed' },
  { dateRange: 'October 01, 2024', taskCategory: 'PRCA and WPRCA', taskDescription: 'WPRCA Application', ticketsAvailable: 'N/A', promotionalPush: 'N/A', dynamicPricing: 'N/A', status: 'Not Started' },

  { dateRange: 'In Progress', taskCategory: 'Brand, Mark, Logo', taskDescription: 'Development', ticketsAvailable: 'N/A', promotionalPush: 'N/A', dynamicPricing: 'N/A', status: 'In Progress' },
  { dateRange: 'October 01, 2024', taskCategory: 'Brand, Mark, Logo', taskDescription: 'Final Review', ticketsAvailable: 'N/A', promotionalPush: 'N/A', dynamicPricing: 'N/A', status: 'Not Started' },
  { dateRange: 'October 08, 2024', taskCategory: 'Brand, Mark, Logo', taskDescription: 'Final', ticketsAvailable: 'N/A', promotionalPush: 'N/A', dynamicPricing: 'N/A', status: 'Not Started' },

  { dateRange: 'October 08, 2024', taskCategory: 'Website and Emails', taskDescription: 'Preliminary Review', ticketsAvailable: 'N/A', promotionalPush: 'N/A', dynamicPricing: 'N/A', status: 'Not Started' },
  { dateRange: 'October 15, 2024', taskCategory: 'Website and Emails', taskDescription: 'Final Review', ticketsAvailable: 'N/A', promotionalPush: 'N/A', dynamicPricing: 'N/A', status: 'Not Started' },
  { dateRange: 'October 22, 2024', taskCategory: 'Website and Emails', taskDescription: 'Launch', ticketsAvailable: 'N/A', promotionalPush: 'N/A', dynamicPricing: 'N/A', status: 'Not Started' },

  { dateRange: 'October 22, 2024', taskCategory: 'Publicist', taskDescription: 'Whisper Campaign', ticketsAvailable: 'N/A', promotionalPush: 'N/A', dynamicPricing: 'N/A', status: 'Not Started' },
  { dateRange: 'November 26, 2024', taskCategory: 'Publicist', taskDescription: 'Concert Announce (partial line-up)', ticketsAvailable: 'N/A', promotionalPush: 'N/A', dynamicPricing: 'N/A', status: 'Not Started' },
  { dateRange: 'December 02-05, 2024', taskCategory: 'Publicist', taskDescription: 'NFR event (announce complete concert line-up)', ticketsAvailable: 'N/A', promotionalPush: 'N/A', dynamicPricing: 'N/A', status: 'Not Started' },
  
  { dateRange: 'September 27, 2024', taskCategory: 'Marketing', taskDescription: 'Initiate Digital Collateral Design', ticketsAvailable: 'N/A', promotionalPush: 'N/A', dynamicPricing: 'N/A', status: 'In Progress' },
  { dateRange: 'October 15, 2024', taskCategory: 'Marketing', taskDescription: 'Proofs Due Digital Collateral', ticketsAvailable: 'N/A', promotionalPush: 'N/A', dynamicPricing: 'N/A', status: 'Not Started' },
  { dateRange: 'October 22, 2024', taskCategory: 'Marketing', taskDescription: 'Final Digital Collateral', ticketsAvailable: 'N/A', promotionalPush: 'N/A', dynamicPricing: 'N/A', status: 'Not Started' },
  { dateRange: 'October 22, 2024', taskCategory: 'Marketing', taskDescription: 'Launch Digital Marketing Campaign', ticketsAvailable: 'N/A', promotionalPush: 'N/A', dynamicPricing: 'N/A', status: 'Not Started' },
  { dateRange: 'October 22, 2024', taskCategory: 'Marketing', taskDescription: 'Initiate Print Collateral Design', ticketsAvailable: 'N/A', promotionalPush: 'N/A', dynamicPricing: 'N/A', status: 'Not Started' },
  { dateRange: 'November 19, 2024', taskCategory: 'Marketing', taskDescription: 'Proofs Due Print Collateral', ticketsAvailable: 'N/A', promotionalPush: 'N/A', dynamicPricing: 'N/A', status: 'Not Started' },
  { dateRange: 'TBD', taskCategory: 'Marketing', taskDescription: 'Have Print Collateral Available for NFR?', ticketsAvailable: 'N/A', promotionalPush: 'N/A', dynamicPricing: 'N/A', status: 'Not Started' },
  { dateRange: 'March 01, 2025', taskCategory: 'Marketing', taskDescription: 'Launch Billboard Marketing Campaign', ticketsAvailable: 'N/A', promotionalPush: 'N/A', dynamicPricing: 'N/A', status: 'Not Started' },
  { dateRange: 'October 01, 2024', taskCategory: 'Marketing', taskDescription: 'Solicit Radio Advertising Campaign Proposals', ticketsAvailable: 'N/A', promotionalPush: 'N/A', dynamicPricing: 'N/A', status: 'Not Started' },
  { dateRange: 'November 05, 2024', taskCategory: 'Marketing', taskDescription: 'Review Final Radio Proposals', ticketsAvailable: 'N/A', promotionalPush: 'N/A', dynamicPricing: 'N/A', status: 'Not Started' },
  { dateRange: 'March 01, 2025', taskCategory: 'Marketing', taskDescription: 'Launch Radio Campaign', ticketsAvailable: 'N/A', promotionalPush: 'N/A', dynamicPricing: 'N/A', status: 'Not Started' },
  { dateRange: 'December 03, 2024', taskCategory: 'Marketing', taskDescription: 'NFR announcement', ticketsAvailable: 'N/A', promotionalPush: 'N/A', dynamicPricing: 'N/A', status: 'Not Started' },
  { dateRange: 'TBD', taskCategory: 'Marketing', taskDescription: 'Broadway Promotional Video', ticketsAvailable: 'N/A', promotionalPush: 'N/A', dynamicPricing: 'N/A', status: 'Not Started' },

  { dateRange: 'TBD', taskCategory: 'Sponsorship and Branding', taskDescription: 'PRCA Partnerships', ticketsAvailable: 'N/A', promotionalPush: 'N/A', dynamicPricing: 'N/A', status: 'Not Started' },

  { dateRange: 'December 3-7, 2024', taskCategory: 'Ticketing', taskDescription: 'Early Bird Ticket Pre-Sale', ticketsAvailable: 'Early Bird GA - $75', promotionalPush: 'Announce via email, social media, website', dynamicPricing: 'Implement dynamic pricing and countdown', status: 'Not Started' },
  { dateRange: 'December 8-31, 2024', taskCategory: 'Ticketing', taskDescription: 'General GA & VIP Ticket Launch', ticketsAvailable: 'GA - $100, VIP - $250 to $400', promotionalPush: 'Launch ads on Google, Facebook, TikTok', dynamicPricing: 'Adjust GA pricing as demand rises', status: 'Not Started' },
  { dateRange: 'January 2-15, 2025', taskCategory: 'Ticketing', taskDescription: 'Weekend Pass Promotion', ticketsAvailable: 'Weekend Pass - $250', promotionalPush: 'Begin partnerships with Spotify and hotels', dynamicPricing: 'Lock-in early pricing, increase by 5-10%', status: 'Not Started' },
  { dateRange: 'January 16-31, 2025', taskCategory: 'Ticketing', taskDescription: 'Social Media & Influencer Campaign', ticketsAvailable: 'GA, VIP, Weekend Pass', promotionalPush: 'Leverage influencers for promotions', dynamicPricing: 'Adjust pricing based on engagement', status: 'Not Started' },
  { dateRange: 'February 1-15, 2025', taskCategory: 'Ticketing', taskDescription: 'San Antonio Rodeo Integration', ticketsAvailable: 'All categories available', promotionalPush: 'Cross-promote during San Antonio Rodeo', dynamicPricing: 'N/A', status: 'Not Started' },
  { dateRange: 'February 16-28, 2025', taskCategory: 'Ticketing', taskDescription: 'VIP Push and Multi-Day Promotions', ticketsAvailable: 'Focus on VIP packages', promotionalPush: 'Increase OOH advertising', dynamicPricing: 'Increase VIP prices by 10-15%', status: 'Not Started' },
  { dateRange: 'March 1-15, 2025', taskCategory: 'Ticketing', taskDescription: 'Country Radio Campaigns', ticketsAvailable: 'All categories available', promotionalPush: 'Partner with local radio for promotions', dynamicPricing: 'N/A', status: 'Not Started' },
  { dateRange: 'March 16-31, 2025', taskCategory: 'Ticketing', taskDescription: 'Paid Media & Targeted Digital Ads', ticketsAvailable: 'All categories available', promotionalPush: 'Increase ad spending targeting segments', dynamicPricing: 'Continue price increases for GA', status: 'Not Started' },
  { dateRange: 'April 1-15, 2025', taskCategory: 'Ticketing', taskDescription: 'Sweepstakes Conclusion and Last-Chance VIP Tickets', ticketsAvailable: 'Focus on GA and remaining VIP', promotionalPush: 'Conclude sweepstakes and announce winners', dynamicPricing: 'Adjust GA prices upwards', status: 'Not Started' },
  { dateRange: 'April 16-30, 2025', taskCategory: 'Ticketing', taskDescription: 'Local Ads & Billboard Campaign', ticketsAvailable: 'All categories available', promotionalPush: 'Final OOH ads in Nashville', dynamicPricing: 'N/A', status: 'Not Started' },
  { dateRange: 'May 1-20, 2025', taskCategory: 'Ticketing', taskDescription: 'Last-Minute Ticket Sales', ticketsAvailable: 'GA and Weekend Passes', promotionalPush: 'Last-minute ads on Google and social media', dynamicPricing: 'Implement countdown on website', status: 'Not Started' },
  { dateRange: 'May 21-28, 2025', taskCategory: 'Ticketing', taskDescription: 'Final Push for On-Site Sales', ticketsAvailable: 'GA and VIP upgrades', promotionalPush: 'Promote last-minute purchases', dynamicPricing: 'N/A', status: 'Not Started' },
  { dateRange: 'May 29-31, 2025', taskCategory: 'Ticketing', taskDescription: 'Event Days', ticketsAvailable: 'On-site availability for GA', promotionalPush: 'Ensure on-site ticketing options', dynamicPricing: 'N/A', status: 'Not Started' },
  { dateRange: 'June 1, 2025', taskCategory: 'Post-Event', taskDescription: 'Load Out', ticketsAvailable: 'N/A', promotionalPush: 'N/A', dynamicPricing: 'N/A', status: 'Not Started' },
  { dateRange: 'June 8-14, 2025', taskCategory: 'Post-Event', taskDescription: 'Conduct stakeholder debriefing sessions', ticketsAvailable: 'N/A', promotionalPush: 'N/A', dynamicPricing: 'N/A', status: 'Not Started' },
  { dateRange: 'June 15-21, 2025', taskCategory: 'Post-Event', taskDescription: 'Analyze economic impact and report findings', ticketsAvailable: 'N/A', promotionalPush: 'N/A', dynamicPricing: 'N/A', status: 'Not Started' },
  { dateRange: 'June 22-30, 2025', taskCategory: 'Post-Event', taskDescription: 'Prepare comprehensive event report', ticketsAvailable: 'N/A', promotionalPush: 'N/A', dynamicPricing: 'N/A', status: 'Not Started' },
];