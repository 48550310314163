import React from 'react';

interface Partner {
  name: string;
  activationIdea: string;
  logoUrl: string;
  category: string;
}

const partners: Partner[] = [
  // Music Industry & Museums
  {
    name: 'Country Music Hall of Fame and Museum',
    activationIdea: 'Offer exclusive tours for rodeo attendees, or collaborate on an exhibit highlighting the historical connections between country music and rodeo culture.',
    logoUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/7d/Country_Music_Hall_of_Fame_Logo.svg/1920px-Country_Music_Hall_of_Fame_Logo.svg.png',
    category: 'Music Industry & Museums'
  },
  {
    name: 'Nashville Songwriters Hall of Fame',
    activationIdea: 'Feature songwriters performing at the rodeo, or create content around the storytelling aspect of both rodeo and country songwriting.',
    logoUrl: 'https://upload.wikimedia.org/wikipedia/en/7/79/Nashville_Songwriters_Hall_of_Fame_logo.svg',
    category: 'Music Industry & Museums'
  },
  
  // Music Publishing Companies
  {
    name: 'Sony/ATV Music Publishing',
    activationIdea: "Partner on songwriter showcases at the rodeo or release exclusive rodeo-themed content from artists under Sony/ATV's catalog.",
    logoUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c6/Sony_Logo.svg/1920px-Sony_Logo.svg.png',
    category: 'Music Publishing Companies'
  },
  {
    name: 'Universal Music Publishing Group (UMPG)',
    activationIdea: 'Co-host songwriting events or live performances with UMPG artists leading up to the event.',
    logoUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/0f/Universal_Music_Group_logo.svg/1920px-Universal_Music_Group_logo.svg.png',
    category: 'Music Publishing Companies'
  },
  {
    name: 'Warner Chappell Music Publishing',
    activationIdea: 'Partner with Warner Chappell writers to create exclusive songs for the event or feature songwriter circles during rodeo after-parties.',
    logoUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a6/Warner_Music_Group_2013_logo.svg/1920px-Warner_Music_Group_2013_logo.svg.png',
    category: 'Music Publishing Companies'
  },
  {
    name: 'Big Machine Music',
    activationIdea: 'Promote exclusive Big Machine Music artist performances at the rodeo or sponsor songwriting contests.',
    logoUrl: 'https://upload.wikimedia.org/wikipedia/commons/1/18/Big_Machine_Records_logo.png',
    category: 'Music Publishing Companies'
  },
  {
    name: 'Black River Publishing',
    activationIdea: 'Collaborate on songwriting sessions or feature Black River songwriters during pre-rodeo events.',
    logoUrl: 'https://www.blackriverpublishing.com/assets/img/black-river-publishing-logo.png',
    category: 'Music Publishing Companies'
  },
  
  // Streaming and Digital Platforms
  {
    name: 'Spotify',
    activationIdea: "Launch exclusive rodeo playlists featuring artists and athletes' favorite songs, with a focus on cowboy culture.",
    logoUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/19/Spotify_logo_without_text.svg/1920px-Spotify_logo_without_text.svg.png',
    category: 'Streaming and Digital Platforms'
  },
  {
    name: 'Apple Music',
    activationIdea: 'Exclusive Music City Rodeo content or interviews available on Apple Music.',
    logoUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/9b/Apple_Music_logo.svg/1920px-Apple_Music_logo.svg.png',
    category: 'Streaming and Digital Platforms'
  },
  {
    name: 'YouTube Music',
    activationIdea: 'Create a behind-the-scenes YouTube series featuring rodeo athletes and country artists preparing for the event.',
    logoUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/Youtube_Music_icon.svg/1920px-Youtube_Music_icon.svg.png',
    category: 'Streaming and Digital Platforms'
  },
  
  // Rodeo-Specific Partners
  {
    name: 'Professional Rodeo Cowboys Association (PRCA)',
    activationIdea: 'PRCA-sanctioned events and broadcast the rodeo on national platforms.',
    logoUrl: 'https://prorodeo.com/assets/image/PRCA_Logo(8).png',
    category: 'Rodeo-Specific Partners'
  },
  {
    name: 'Wrangler National Finals Rodeo (NFR)',
    activationIdea: 'Promote the rodeo at NFR with cross-promotional ads and exclusive content.',
    logoUrl: 'https://upload.wikimedia.org/wikipedia/en/thumb/4/4c/Wrangler_Logo.svg/1920px-Wrangler_Logo.svg.png',
    category: 'Rodeo-Specific Partners'
  },
  {
    name: 'Cowboy Channel',
    activationIdea: 'Livestream rodeo events and exclusive interviews with athletes.',
    logoUrl: 'https://www.thecowboychannel.com/wp-content/uploads/2021/10/cc-logo.png',
    category: 'Rodeo-Specific Partners'
  },
  {
    name: 'PBR (Professional Bull Riders)',
    activationIdea: 'Partner with PBR to host special bull-riding exhibitions as part of the event.',
    logoUrl: 'https://upload.wikimedia.org/wikipedia/en/thumb/e/e3/Professional_Bull_Riders_logo.svg/1920px-Professional_Bull_Riders_logo.svg.png',
    category: 'Rodeo-Specific Partners'
  },
  
  // Retailers
  {
    name: 'Tractor Supply Co.',
    activationIdea: 'Sponsor exclusive merchandise for rodeo fans or provide gear discounts on western apparel.',
    logoUrl: 'https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Tractor_Supply_Company_logo.svg/1920px-Tractor_Supply_Company_logo.svg.png',
    category: 'Retailers'
  },
  {
    name: 'Boot Barn',
    activationIdea: 'Exclusive Music City Rodeo boots or on-site fittings for attendees.',
    logoUrl: 'https://upload.wikimedia.org/wikipedia/commons/c/c3/Boot_Barn_logo_blue.png',
    category: 'Retailers'
  },
  
  // Grocers
  {
    name: 'Kroger',
    activationIdea: 'Partner with Kroger for family ticket packages with grocery discounts or offer giveaways for rodeo attendees.',
    logoUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a2/Kroger_logo.svg/1920px-Kroger_logo.svg.png',
    category: 'Grocers'
  },
  {
    name: 'Publix',
    activationIdea: 'Collaborate on family meal packages or sponsor family-friendly zones at the event.',
    logoUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fd/Publix_Logo.svg/1920px-Publix_Logo.svg.png',
    category: 'Grocers'
  },
  
  // Veteran and Military Organizations
  {
    name: 'Wounded Warrior Project',
    activationIdea: 'Military appreciation night with discounted rodeo tickets for veterans.',
    logoUrl: 'https://upload.wikimedia.org/wikipedia/en/thumb/e/eb/Wounded_Warrior_Project_logo.svg/1920px-Wounded_Warrior_Project_logo.svg.png',
    category: 'Veteran and Military Organizations'
  },
  
  // Local Nashville Businesses and Institutions
  {
    name: 'Hilton Hotels',
    activationIdea: 'VIP hotel-and-ticket packages including rodeo access and pre-event hospitality.',
    logoUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/57/Hilton_Logo.svg/1920px-Hilton_Logo.svg.png',
    category: 'Local Nashville Businesses and Institutions'
  },
  {
    name: 'Omni Nashville Hotel',
    activationIdea: 'Host VIP after-parties or meet-and-greet events at Omni.',
    logoUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/Omni_Hotels_and_Resorts_Logo.svg/1920px-Omni_Hotels_and_Resorts_Logo.svg.png',
    category: 'Local Nashville Businesses and Institutions'
  },
  
  // Nashville Sports Teams
  {
    name: 'Tennessee Titans (NFL)',
    activationIdea: 'Titans-themed ticket bundles, including game tickets and rodeo access, or host a "Titans Night at the Rodeo."',
    logoUrl: 'https://upload.wikimedia.org/wikipedia/en/thumb/c/c1/Tennessee_Titans_logo.svg/1920px-Tennessee_Titans_logo.svg.png',
    category: 'Nashville Sports Teams'
  },
  {
    name: 'Nashville Predators (NHL)',
    activationIdea: 'Combine rodeo ticket promotions with Predators games or host player appearances at the rodeo event.',
    logoUrl: 'https://upload.wikimedia.org/wikipedia/en/thumb/9/9a/Nashville_Predators_logo.svg/1920px-Nashville_Predators_logo.svg.png',
    category: 'Nashville Sports Teams'
  },
];

const Partnerships: React.FC = () => {
  const categories = Array.from(new Set(partners.map(partner => partner.category)));

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold mb-8 text-center">Music City Rodeo Partnerships</h1>
      
      {categories.map(category => (
        <div key={category} className="mb-12">
          <h2 className="text-2xl font-semibold mb-4">{category}</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {partners.filter(partner => partner.category === category).map((partner, index) => (
              <div
                key={index}
                className="bg-white rounded-lg shadow-md overflow-hidden"
              >
                <div className="p-4 flex items-center justify-center h-40 bg-gray-100">
                  <img
                    src={partner.logoUrl}
                    alt={`${partner.name} Logo`}
                    className="max-h-full max-w-full object-contain"
                  />
                </div>
                <div className="p-4">
                  <h3 className="text-xl font-semibold mb-2">{partner.name}</h3>
                  <p className="text-gray-700">{partner.activationIdea}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Partnerships;