import React from 'react';
import { motion } from 'framer-motion';
import { Line, Bar, Pie } from 'react-chartjs-2';
import { ChartOptions, ChartData } from 'chart.js';
import 'chart.js/auto';

// Tourism Growth Chart Data
const tourismData: ChartData<'line'> = {
  labels: ['2018', '2019', '2020', '2021', '2022', '2023', '2027'],
  datasets: [
    {
      label: 'Tourists (millions)',
      data: [15.2, 16.1, 7, 12.6, 14.4, 15.5, 18],
      fill: false,
      backgroundColor: '#4C51BF',
      borderColor: '#5A67D8',
    },
  ],
};

// Economic Impact Chart Data
const economicImpactData: ChartData<'bar'> = {
  labels: ['Tourism (2022)', 'Music City Center (2022)', 'SEC Basketball Tournament (2023)', 'CMA Music Festival (2022)', 'NFL Draft (2019)'],
  datasets: [
    {
      label: 'Economic Impact (millions USD)',
      data: [7000, 500, 22, 66, 133],
      backgroundColor: [
        '#4C51BF',
        '#5A67D8',
        '#667EEA',
        '#7F9CF5',
        '#A3BFFA',
      ],
    },
  ],
};

// Music Industry Contribution Chart Data
const musicIndustryData: ChartData<'pie'> = {
  labels: ['Music Industry', 'Other Sectors'],
  datasets: [
    {
      data: [10, 140], // 10 billion from music industry, assuming total GDP of 150 billion
      backgroundColor: ['#F56565', '#E53E3E'],
      hoverBackgroundColor: ['#FC8181', '#F56565'],
    },
  ],
};

// Major Business Investments Chart Data
const businessInvestmentsData: ChartData<'bar'> = {
  labels: ['Amazon', 'Oracle', 'Other Tech Companies'],
  datasets: [
    {
      label: 'Investment (millions USD)',
      data: [230, 1200, 500], // Estimated data
      backgroundColor: ['#4C51BF', '#5A67D8', '#667EEA'],
    },
    {
      label: 'Jobs Created',
      data: [5000, 8500, 3000], // Estimated data
      backgroundColor: ['#F6E05E', '#ECC94B', '#D69E2E'],
    },
  ],
};

// Updated chart options
const lineChartOptions: ChartOptions<'line'> = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
    },
  },
  animation: {
    duration: 1000,
    easing: 'easeOutQuart',
  },
};

const barChartOptions: ChartOptions<'bar'> = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
    },
  },
  animation: {
    duration: 1000,
    easing: 'easeOutQuart',
  },
};

const pieChartOptions: ChartOptions<'pie'> = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
    },
  },
  animation: {
    duration: 1000,
    easing: 'easeOutQuart',
  },
};

const AboutNashville: React.FC = () => {
  return (
    <div className="container mx-auto p-8 bg-white">
      {/* Hero Section */}
      <div className="relative bg-cover bg-center h-screen flex items-center justify-center"
        style={{ backgroundImage: "url('/images/nashville.jpg')" }}>
        <div className="bg-black bg-opacity-50 p-8 rounded-lg">
          <h1 className="text-6xl font-bold text-white mb-4">Nashville: A City on Fire</h1>
          <p className="text-2xl text-white">Where Music, Business, and Tourism Thrive</p>
        </div>
      </div>

      {/* Tourism Section */}
      <motion.div initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.8 }} className="mb-16">
        <h2 className="text-4xl font-bold mb-8 text-center">Tourism in Nashville</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 mb-16">
          <div>
            <h3 className="text-2xl font-bold mb-4">Tourism Growth</h3>
            <Line data={tourismData} options={lineChartOptions} />
          </div>
          <div>
            <h3 className="text-2xl font-bold mb-4">Economic Impact of Major Events</h3>
            <Bar data={economicImpactData} options={barChartOptions} />
          </div>
        </div>
        <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
          <h3 className="text-2xl font-bold mb-4">Key Tourism Facts</h3>
          <ul className="list-disc pl-6">
            <li>Nashville attracted 16.1 million visitors in 2019, breaking previous records.</li>
            <li>In 2022, Nashville was among the top tourist destinations in the U.S., with over 14.4 million tourists.</li>
            <li>Broadway in downtown Nashville is one of the highest-grossing streets in the country for beer sales, boasting an estimated $1.5 billion annual sales.</li>
            <li>Nashville's tourism industry generated over $7.5 billion in economic impact in 2019.</li>
            <li>Tourism alone supports 1 in 10 jobs in the city, which equates to over 74,000 jobs.</li>
          </ul>
        </div>
      </motion.div>

      {/* Music Industry Section */}
      <motion.div initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.8 }} className="mb-16">
        <h2 className="text-4xl font-bold mb-8 text-center">Music Industry Influence</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 mb-16">
          <div>
            <h3 className="text-2xl font-bold mb-4">Music Industry Contribution to GDP</h3>
            <Pie data={musicIndustryData} options={pieChartOptions} />
          </div>
          <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
            <h3 className="text-2xl font-bold mb-4">Music Industry Facts</h3>
            <ul className="list-disc pl-6">
              <li>Nashville's music industry contributes over $10 billion annually to the city's economy.</li>
              <li>60% of all tourists who visit Nashville attend a live music performance.</li>
              <li>Music Row is home to over 300 recording studios, publishers, and record labels.</li>
              <li>The CMA Music Festival in 2022 attracted over 80,000 visitors, generating $66 million in economic impact.</li>
            </ul>
          </div>
        </div>
      </motion.div>

      {/* Major Businesses Section */}
      <motion.div initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.8 }} className="mb-16">
        <h2 className="text-4xl font-bold mb-8 text-center">Major Businesses in Nashville</h2>
        <div className="mb-16">
          <h3 className="text-2xl font-bold mb-4">Major Business Investments</h3>
          <Bar data={businessInvestmentsData} options={barChartOptions} />
        </div>
        <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
          <h3 className="text-2xl font-bold mb-4">Key Business Facts</h3>
          <ul className="list-disc pl-6">
            <li>Amazon's Operations Center of Excellence created 5,000 new jobs with an investment of over $230 million.</li>
            <li>Oracle is investing $1.2 billion to build a new campus in Nashville that will create 8,500 new jobs.</li>
            <li>Nashville's growing tech sector includes major offices from companies like Spotify and Apple Music.</li>
          </ul>
        </div>
      </motion.div>

      {/* Economic Growth Section */}
      <motion.div initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.8 }} className="mb-16">
        <h2 className="text-4xl font-bold mb-8 text-center">Economic Growth</h2>
        <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
          <h3 className="text-2xl font-bold mb-4">Economic Highlights</h3>
          <ul className="list-disc pl-6">
            <li>Nashville's GDP has been growing steadily, with a 4.3% annual growth rate, outpacing the national average.</li>
            <li>In 2022, the city's GDP exceeded $150 billion.</li>
            <li>Nashville's unemployment rate remains low, hovering around 2.7%, well below the national average.</li>
          </ul>
        </div>
      </motion.div>

      {/* Sporting & Cultural Influence Section */}
      <motion.div initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.8 }} className="mb-16">
        <h2 className="text-4xl font-bold mb-8 text-center">Sporting & Cultural Influence</h2>
        <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
          <h3 className="text-2xl font-bold mb-4">Sports and Culture Facts</h3>
          <ul className="list-disc pl-6">
            <li>Nashville is home to the Tennessee Titans (NFL), Nashville Predators (NHL), and Nashville SC (MLS).</li>
            <li>The Nashville Predators and Bridgestone Arena generated over $560 million in economic impact in 2022.</li>
            <li>Nashville SC's new stadium, Geodis Park, opened in 2022 and is the largest soccer-specific stadium in the U.S., seating over 30,000 fans.</li>
            <li>Nashville was ranked the friendliest city in the U.S. by Condé Nast Traveler in 2021.</li>
          </ul>
        </div>
      </motion.div>

      {/* Quotes from Celebrities Section */}
      <motion.div initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.8 }} className="mb-16">
        <h2 className="text-4xl font-semibold text-gray-900 text-center mb-6">What Celebrities Say About Nashville</h2>
        <div className="flex flex-col space-y-4">
          <blockquote className="text-xl italic font-semibold text-gray-700">
            "Nashville is like no other place. It's the soul of American music." — Tim McGraw
          </blockquote>
          <blockquote className="text-xl italic font-semibold text-gray-700">
            "The energy in Nashville is electric. It's no surprise people keep flocking here." — Dolly Parton
          </blockquote>
          <blockquote className="text-xl italic font-semibold text-gray-700">
            "Nashville has a unique blend of creativity and entrepreneurship that is unmatched." — Taylor Swift
          </blockquote>
        </div>
      </motion.div>
    </div>
  );
};

export default AboutNashville;