import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FaCheckCircle, FaExclamationTriangle } from 'react-icons/fa';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LineChart, Line, PieChart, Pie, Cell } from 'recharts';

const CompetitiveAnalysis: React.FC = () => {
  const [filter, setFilter] = useState('all');

  const competitiveData = [
    { name: 'Music City Rodeo', attendance: 400000, revenue: 100, satisfaction: 95 },
    { name: 'Houston Livestock Show and Rodeo', attendance: 2500000, revenue: 180, satisfaction: 90 },
    { name: 'Cheyenne Frontier Days', attendance: 250000, revenue: 40, satisfaction: 85 },
    { name: 'Calgary Stampede', attendance: 1200000, revenue: 150, satisfaction: 88 },
  ];

  const filteredData = filter === 'all' ? competitiveData : competitiveData.filter(item => item.name === filter);

  const strengthsWeaknesses = [
    { type: 'Strength', points: [
      'Unique blend of rodeo and country music performances',
      'Strategic location in Nashville, a major music hub',
      'Potential for rapid growth and expansion',
      'Strong partnerships with top country music artists'
    ]},
    { type: 'Weakness', points: [
      'Newer event with less established reputation',
      'Initially smaller scale compared to major rodeos',
      'Weather-dependent outdoor elements',
      'Competition for talent with established events'
    ]}
  ];

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  const pieChartData = competitiveData.map(item => ({
    name: item.name,
    value: item.attendance
  }));

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="bg-white rounded-lg shadow-lg p-6"
    >
      <h2 className="text-3xl font-bold mb-6">Competitive Analysis</h2>
      
      <motion.p
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.2 }}
        className="mb-6"
      >
        The Music City Rodeo enters a competitive landscape of established rodeo events, but brings a unique value proposition by deeply integrating Nashville's country music scene. Here's how we compare to other major events:
      </motion.p>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.4 }}
        className="mb-4"
      >
        <label className="mr-2">Filter by event:</label>
        <select
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          className="p-2 border rounded"
        >
          <option value="all">All Events</option>
          {competitiveData.map(item => (
            <option key={item.name} value={item.name}>{item.name}</option>
          ))}
        </select>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ delay: 0.6 }}
        className="mb-8"
        style={{ height: '400px' }}
      >
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={filteredData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
            <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
            <Tooltip />
            <Legend />
            <Bar yAxisId="left" dataKey="attendance" fill="#8884d8" name="Annual Attendance">
              {filteredData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Bar>
            <Bar yAxisId="right" dataKey="revenue" fill="#82ca9d" name="Annual Revenue ($M)">
              {filteredData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[(index + 2) % COLORS.length]} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ delay: 0.8 }}
        className="mb-8"
        style={{ height: '400px' }}
      >
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={filteredData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="satisfaction" stroke="#8884d8" activeDot={{ r: 8 }} />
          </LineChart>
        </ResponsiveContainer>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ delay: 1 }}
        className="mb-8"
        style={{ height: '400px' }}
      >
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={pieChartData}
              cx="50%"
              cy="50%"
              labelLine={false}
              outerRadius={150}
              fill="#8884d8"
              dataKey="value"
              label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
            >
              {pieChartData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </motion.div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
        {strengthsWeaknesses.map((item, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, x: index % 2 === 0 ? -20 : 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 1.2 + index * 0.2 }}
            className={`p-4 rounded-lg ${item.type === 'Strength' ? 'bg-green-100' : 'bg-yellow-100'}`}
          >
            <h3 className="text-xl font-semibold mb-2 flex items-center">
              {item.type === 'Strength' ? (
                <FaCheckCircle className="text-green-500 mr-2" />
              ) : (
                <FaExclamationTriangle className="text-yellow-500 mr-2" />
              )}
              {item.type}s
            </h3>
            <ul className="list-disc pl-6">
              {item.points.map((point, idx) => (
                <motion.li
                  key={idx}
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 1.4 + index * 0.2 + idx * 0.1 }}
                >
                  {point}
                </motion.li>
              ))}
            </ul>
          </motion.div>
        ))}
      </div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 1.8 }}
        className="bg-blue-50 p-6 rounded-lg"
      >
        <h3 className="text-xl font-semibold mb-4">Competitive Advantages</h3>
        <ul className="list-disc pl-6 space-y-2">
          <motion.li
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 2 }}
          >
            Unique positioning as a premier rodeo and country music festival hybrid
          </motion.li>
          <motion.li
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 2.1 }}
          >
            Strong potential for growth and expansion into new markets
          </motion.li>
          <motion.li
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 2.2 }}
          >
            Leveraging Nashville's existing tourism infrastructure and appeal
          </motion.li>
          <motion.li
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 2.3 }}
          >
            Ability to attract top-tier country music talent due to location
          </motion.li>
          <motion.li
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 2.4 }}
          >
            Focus on creating a more intimate, high-quality experience compared to larger events
          </motion.li>
        </ul>
      </motion.div>
    </motion.div>
  );
};

export default CompetitiveAnalysis;