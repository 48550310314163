import { ChartOptions } from 'chart.js';

// Common options that can be shared across chart types
const commonOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    tooltip: {
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      titleFont: { size: 14 },
      bodyFont: { size: 12 },
      padding: 12,
    },
  },
  layout: {
    padding: { top: 20, right: 20, bottom: 20, left: 20 },
  },
};

// Bar chart options
export const barChartOptions: ChartOptions<'bar'> = {
  ...commonOptions,
  scales: {
    x: { grid: { display: false } },
    y: { beginAtZero: true, grid: { color: 'rgba(0, 0, 0, 0.1)' } },
  },
  plugins: {
    ...commonOptions.plugins,
    legend: {
      position: 'top' as const,
      labels: { usePointStyle: true, padding: 20, font: { size: 12 } },
    },
  },
};

// Line chart options
export const lineChartOptions: ChartOptions<'line'> = {
  ...commonOptions,
  scales: {
    x: { grid: { display: false } },
    y: { beginAtZero: true, grid: { color: 'rgba(0, 0, 0, 0.1)' } },
  },
  plugins: {
    ...commonOptions.plugins,
    legend: {
      position: 'top' as const,
      labels: { usePointStyle: true, padding: 20, font: { size: 12 } },
    },
  },
};

// Pie chart options
export const pieChartOptions: ChartOptions<'pie'> = {
  ...commonOptions,
  plugins: {
    ...commonOptions.plugins,
    legend: {
      position: 'right' as const,
      labels: { usePointStyle: true, padding: 20, font: { size: 12 } },
    },
  },
};

// Doughnut chart options
export const doughnutChartOptions: ChartOptions<'doughnut'> = {
  ...commonOptions,
  cutout: '60%',
  plugins: {
    ...commonOptions.plugins,
    legend: {
      position: 'bottom' as const,
      labels: { usePointStyle: true, padding: 20, font: { size: 12 } },
    },
  },
};

// Export all chart options
export const chartOptions = {
  bar: barChartOptions,
  line: lineChartOptions,
  pie: pieChartOptions,
  doughnut: doughnutChartOptions,
};