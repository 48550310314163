import React from 'react';
import { motion } from 'framer-motion';
import { FaHorse, FaCalendarAlt, FaBullhorn, FaUsers } from 'react-icons/fa';
import { Line } from 'react-chartjs-2';
import { ChartOptions, ChartData } from 'chart.js';
import 'chart.js/auto';

interface EventCardProps {
  title: string;
  videoUrl?: string;
  description: string;
}

// Function to display event cards with PRCA videos
const EventCard: React.FC<EventCardProps> = ({ title, videoUrl, description }) => (
  <motion.div
    className="bg-white shadow-lg rounded-lg p-4 mb-8"
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.3 }}
  >
    <h3 className="text-2xl font-bold mb-4">{title}</h3>
    <p className="mb-4">{description}</p>
    {videoUrl && (
      <iframe
        width="100%"
        height="315"
        src={videoUrl}
        title={title}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    )}
  </motion.div>
);

const AboutRodeo: React.FC = () => {
  // Mock data for the attendance chart
  const attendanceData: ChartData<'line'> = {
    labels: ['2017', '2018', '2019', '2020', '2021'],
    datasets: [
      {
        label: 'Rodeo Attendance',
        data: [150000, 170000, 200000, 100000, 180000],
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1
      }
    ]
  };

  const chartOptions: ChartOptions<'line'> = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Rodeo Attendance Growth'
      }
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen p-8">
      <motion.h1 
        className="text-4xl font-bold text-center mb-12"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        Rodeo: The Complete Breakdown
      </motion.h1>

      <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Section 1: Intro to Rodeo */}
        <motion.section 
          className="bg-white rounded-lg shadow-lg p-6 mb-8 col-span-1 md:col-span-2"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          <h2 className="text-3xl font-semibold mb-4 flex items-center">
            <FaHorse className="mr-2 text-blue-500" /> What is Rodeo?
          </h2>
          <p>
            Rodeo is a sport rooted in the traditions of cattle ranching and cowboy culture. Over time, it has evolved into a modern competitive sport with a massive following across North America. 
            Competitors test their agility, strength, and skill, both in handling animals and completing timed events. Today, rodeo is a multi-million-dollar industry with professional athletes competing for high-stakes purses and national recognition.
          </p>
        </motion.section>

        {/* Section 2: PRCA Overview */}
        <motion.section 
          className="bg-white rounded-lg shadow-lg p-6 mb-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4, duration: 0.5 }}
        >
          <h2 className="text-3xl font-semibold mb-4 flex items-center">
            <FaBullhorn className="mr-2 text-green-500" /> What is the PRCA?
          </h2>
          <p>
            The Professional Rodeo Cowboys Association (PRCA), founded in 1936, is the oldest and largest sanctioning body for professional rodeo. 
            The PRCA sanctions over 650 rodeos annually across 44 U.S. states and Canada. It governs a wide range of rodeo events, ensuring that all rodeos meet strict standards of safety, animal welfare, and professionalism.
          </p>
          <ul className="list-disc pl-6 mt-4">
            <li><strong>Sanctioned Rodeos:</strong> The PRCA oversees everything from small local events to iconic rodeos like the Cheyenne Frontier Days.</li>
            <li><strong>National Finals Rodeo (NFR):</strong> The NFR is the championship event held annually in Las Vegas, featuring the top 15 competitors in each rodeo event.</li>
            <li><strong>Animal Welfare:</strong> The PRCA enforces strict rules to ensure that all animals are treated with respect and care during rodeo events.</li>
          </ul>
        </motion.section>

        {/* Section 3: PRCA vs. PBR */}
        <motion.section 
          className="bg-white rounded-lg shadow-lg p-6 mb-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.6, duration: 0.5 }}
        >
          <h2 className="text-3xl font-semibold mb-4 flex items-center">
            <FaUsers className="mr-2 text-purple-500" /> PRCA vs. PBR
          </h2>
          <p>
            While both organizations focus on rodeo sports, there are significant differences between the PRCA (Professional Rodeo Cowboys Association) and PBR (Professional Bull Riders).
          </p>
          <ul className="list-disc pl-6 mt-4">
            <li><strong>PRCA:</strong> Governs multiple events such as bronc riding, roping, and barrel racing.</li>
            <li><strong>PBR:</strong> Focuses exclusively on bull riding, attracting huge crowds and large prize pools.</li>
          </ul>
        </motion.section>

        {/* Section 4: Culture of Rodeo */}
        <motion.section 
          className="bg-white rounded-lg shadow-lg p-6 mb-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.8, duration: 0.5 }}
        >
          <h2 className="text-3xl font-semibold mb-4 flex items-center">
            <FaUsers className="mr-2 text-yellow-500" /> The Culture of Rodeo
          </h2>
          <p>
            At its core, rodeo reflects the values of the American West. Cowboys and cowgirls compete to demonstrate their mastery over wild animals, their skill on horseback, and their toughness in a physically demanding environment.
          </p>
          <ul className="list-disc pl-6 mt-4">
            <li><strong>Community:</strong> Rodeo fosters a strong sense of camaraderie and community among its participants.</li>
            <li><strong>Family-Oriented:</strong> Rodeo is often a family affair, with competitors training from a young age.</li>
            <li><strong>Western Heritage:</strong> Rodeo reflects the historical and cultural elements of the American cowboy.</li>
          </ul>
        </motion.section>

        {/* Section 5: Events in Rodeo */}
        <motion.section 
          className="bg-white rounded-lg shadow-lg p-6 mb-8 col-span-1 md:col-span-2"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1, duration: 0.5 }}
        >
          <h2 className="text-3xl font-semibold mb-4 flex items-center">
            <FaCalendarAlt className="mr-2 text-red-500" /> Rodeo Events
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <EventCard 
              title="Bareback Riding"
              videoUrl="https://www.youtube.com/embed/xyz" 
              description="Cowboys must stay on a bucking horse for 8 seconds while holding onto a rigging." 
            />
            <EventCard 
              title="Saddle Bronc Riding"
              videoUrl="https://www.youtube.com/embed/xyz" 
              description="Riders must stay on a bucking horse for 8 seconds using a specialized saddle."
            />
            <EventCard 
              title="Bull Riding"
              videoUrl="https://www.youtube.com/embed/xyz" 
              description="The most dangerous rodeo event, where riders must stay on a bucking bull for 8 seconds."
            />
            <EventCard 
              title="Steer Wrestling"
              videoUrl="https://www.youtube.com/embed/xyz" 
              description="A cowboy jumps from his horse onto the back of a running steer, attempting to wrestle it to the ground."
            />
          </div>
        </motion.section>

        {/* Section 6: Rodeo Attendance Chart */}
        <motion.section 
          className="bg-white rounded-lg shadow-lg p-6 mb-8 col-span-1 md:col-span-2"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1.2, duration: 0.5 }}
        >
          <h2 className="text-3xl font-semibold mb-4 flex items-center">
            <FaUsers className="mr-2 text-blue-500" /> Rodeo Attendance Growth
          </h2>
          <Line data={attendanceData} options={chartOptions} />
        </motion.section>

        {/* Section 7: Annual PRCA Schedule */}
        <motion.section 
          className="bg-white rounded-lg shadow-lg p-6 mb-8 col-span-1 md:col-span-2"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1.4, duration: 0.5 }}
        >
          <h2 className="text-3xl font-semibold mb-4 flex items-center">
            <FaCalendarAlt className="mr-2 text-green-500" /> Annual PRCA Schedule
          </h2>
          <p className="mb-6">
            The PRCA rodeo season runs year-round, with major events happening across the U.S. and Canada. Competitors aim to accumulate enough points to qualify for the National Finals Rodeo (NFR), held every December in Las Vegas. Here's a look at some of the major rodeos throughout the year:
          </p>
          <table className="table-auto w-full text-left border-collapse">
            <thead>
              <tr className="bg-gray-200">
                <th className="border-b px-4 py-2">Month</th>
                <th className="border-b px-4 py-2">Rodeo Event</th>
                <th className="border-b px-4 py-2">Location</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border-b px-4 py-2">January</td>
                <td className="border-b px-4 py-2">National Western Stock Show & Rodeo</td>
                <td className="border-b px-4 py-2">Denver, CO</td>
              </tr>
              <tr className="bg-gray-100">
                <td className="border-b px-4 py-2">March</td>
                <td className="border-b px-4 py-2">Houston Livestock Show & Rodeo</td>
                <td className="border-b px-4 py-2">Houston, TX</td>
              </tr>
              <tr>
                <td className="border-b px-4 py-2">July</td>
                <td className="border-b px-4 py-2">Cheyenne Frontier Days</td>
                <td className="border-b px-4 py-2">Cheyenne, WY</td>
              </tr>
              <tr className="bg-gray-100">
                <td className="border-b px-4 py-2">December</td>
                <td className="border-b px-4 py-2">National Finals Rodeo (NFR)</td>
                <td className="border-b px-4 py-2">Las Vegas, NV</td>
              </tr>
            </tbody>
          </table>
        </motion.section>

        {/* Section 8: Top Cowboys & Cowgirls */}
        <motion.section 
          className="bg-white rounded-lg shadow-lg p-6 mb-8 col-span-1 md:col-span-2"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1.6, duration: 0.5 }}
        >
          <h2 className="text-3xl font-semibold mb-4">Top Cowboys & Cowgirls</h2>
          <p className="mb-6">
            Rodeo athletes are among the toughest competitors in the world. Here's a list of some of the top cowboys and cowgirls, both past and present, who have made their mark on the sport.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <EventCard 
              title="Trevor Brazile (All-Around)"
              description="With a record 26 PRCA World Championships, Trevor Brazile is regarded as the greatest cowboy of all time."
              videoUrl="https://www.youtube.com/embed/xyz"
            />
            <EventCard 
              title="Sage Kimzey (Bull Riding)"
              description="A six-time PRCA World Champion Bull Rider, Sage Kimzey continues to dominate the bull riding scene."
            />
            <EventCard 
              title="Hailey Kinsel (Barrel Racing)"
              description="Three-time Barrel Racing World Champion, Hailey Kinsel is known for her speed and precision in the arena."
              videoUrl="https://www.youtube.com/embed/xyz"
            />
            <EventCard 
              title="Stetson Wright (All-Around)"
              description="The youngest cowboy to win the All-Around World Title, Stetson Wright competes in both Saddle Bronc and Bull Riding."
            />
          </div>
        </motion.section>

        {/* Section 9: Rodeo Terms and Dictionary Link */}
        <motion.section 
          className="bg-white rounded-lg shadow-lg p-6 mb-8 col-span-1 md:col-span-2"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1.8, duration: 0.5 }}
        >
          <h2 className="text-3xl font-semibold mb-4 flex items-center">
            <FaBullhorn className="mr-2 text-purple-500" /> Rodeo Terms to Know
          </h2>
          <p className="mb-4">
            Want to learn more about the specific terms and jargon used in rodeo? Visit our comprehensive <a href="/rodeo-dictionary" className="text-blue-600 underline">Rodeo Dictionary</a> for a full breakdown of the terms, rules, and equipment used in the sport.
          </p>
          <div className="text-center">
            <a href="/rodeo-dictionary" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
              Explore the Rodeo Dictionary
            </a>
          </div>
        </motion.section>
      </div>
    </div>
  );
};

export default AboutRodeo;