import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FaHorse, FaAward, FaGlobe } from 'react-icons/fa';

const RodeoHistory: React.FC = () => {
  return (
    <div className="rodeo-history bg-gray-100 min-h-screen p-8">
      <h1 className="text-4xl font-bold mb-8 text-center text-indigo-800">The Rich History of Rodeo</h1>

      <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-lg p-8 mb-12">
        <p className="text-lg mb-4">
          Rodeo, a sport that embodies the spirit of the American West, has a rich and colorful history spanning over 150 years. 
          From its humble beginnings as a showcase of ranching skills to its evolution into a professional sport, 
          rodeo has captured the hearts of millions and become an integral part of American culture.
        </p>
        <div className="flex justify-center">
          <img src="/images/vintage-rodeo.jpg" alt="Vintage Rodeo" className="rounded-lg shadow-md max-w-full h-auto" />
        </div>
      </div>

      <VerticalTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
          date="1820s - 1860s"
          iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          icon={<FaHorse />}
        >
          <h3 className="vertical-timeline-element-title">The Birth of Rodeo</h3>
          <p>
            Rodeo emerged from the working practices of cattle herding in Spain, Mexico, and later the American West.
            Cowboys developed skills to manage cattle, which later evolved into competitive events.
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(16, 204, 82)' }}
          date="1869"
          iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
          icon={<FaHorse />}
        >
          <h3 className="vertical-timeline-element-title">First Formal Rodeo</h3>
          <p>
            The first recorded formal rodeo was held in Deer Trail, Colorado.
            This event marked the transition of rodeo from a casual competition to an organized sport.
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(233, 30, 99)' }}
          date="1929"
          iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
          icon={<FaAward />}
        >
          <h3 className="vertical-timeline-element-title">Rodeo Association of America</h3>
          <p>
            The Rodeo Association of America was formed to establish standard rules and regulations for rodeo competitions.
            This helped legitimize rodeo as a professional sport.
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: 'rgb(245, 124, 0)', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(245, 124, 0)' }}
          date="1936"
          iconStyle={{ background: 'rgb(245, 124, 0)', color: '#fff' }}
          icon={<FaGlobe />}
        >
          <h3 className="vertical-timeline-element-title">Cowboys' Turtle Association</h3>
          <p>
            The Cowboys' Turtle Association was formed, which later became the Professional Rodeo Cowboys Association (PRCA).
            This organization played a crucial role in standardizing rodeo events and improving conditions for competitors.
          </p>
        </VerticalTimelineElement>
      </VerticalTimeline>

      <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-lg p-8 mt-12">
        <h2 className="text-2xl font-bold mb-4 text-indigo-800">Rodeo Today</h2>
        <p className="text-lg mb-4">
          Today, rodeo continues to thrill audiences worldwide, combining athleticism, skill, and the enduring spirit of the American West. 
          Professional rodeo events attract millions of spectators annually and showcase the incredible talents of both human and animal athletes.
        </p>
        <div className="flex justify-center">
          <img src="/images/modern-rodeo.jpg" alt="Modern Rodeo" className="rounded-lg shadow-md max-w-full h-auto" />
        </div>
      </div>
    </div>
  );
};

export default RodeoHistory;
