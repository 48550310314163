import React from 'react';
import { motion } from 'framer-motion';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FaMusic, FaHorse, FaGuitar, FaMicrophone, FaHeadphones, FaHatCowboy, FaTicketAlt, FaChartLine } from 'react-icons/fa';

const RodeoAndMusicPage: React.FC = () => {
  const timelineEvents = [
    {
      year: "1942",
      title: "Houston Rodeo's First Musical Performance",
      description: "Gene Autry, the 'Singing Cowboy', performs at the Houston Rodeo, marking the beginning of live music at major rodeo events.",
      icon: <FaMicrophone />,
    },
    {
      year: "1956",
      title: "Elvis Presley at the Houston Rodeo",
      description: "Elvis Presley performs at the Houston Rodeo, drawing massive crowds and solidifying the connection between rodeo and popular music.",
      icon: <FaGuitar />,
    },
    {
      year: "1960s",
      title: "Country Music Dominance",
      description: "Nashville-based country music becomes the dominant genre in rodeo events, with many country stars performing at major rodeos across the country.",
      icon: <FaMusic />,
    },
    {
      year: "1970s",
      title: "Outlaw Country Movement",
      description: "The outlaw country movement, with roots in Nashville, brings a rebellious spirit to rodeo music, resonating with the cowboy culture and rodeo lifestyle.",
      icon: <FaHatCowboy />,
    },
    {
      year: "1980s",
      title: "Rodeo Concerts Boost Ticket Sales",
      description: "Rodeos with live concerts see a significant increase in ticket sales, some reporting up to 30% higher attendance compared to non-concert events.",
      icon: <FaTicketAlt />,
    },
    {
      year: "1990s",
      title: "Nashville's Global Country Music Influence",
      description: "Nashville solidifies its position as the global capital of country music, exporting the genre worldwide and strengthening its ties with rodeo culture.",
      icon: <FaChartLine />,
    },
    {
      year: "2000s",
      title: "Diverse Music Genres in Rodeos",
      description: "Rodeos begin featuring a wider variety of music genres, including rock and pop, appealing to broader audiences while maintaining country music's strong presence.",
      icon: <FaHeadphones />,
    },
    {
      year: "2010s",
      title: "Technology and Production Advancements",
      description: "Advanced sound systems and elaborate stage productions elevate the music experience at rodeo events, creating immersive performances that rival standalone concerts.",
      icon: <FaMicrophone />,
    },
    {
      year: "2023",
      title: "Music City Rodeo Launches",
      description: "The inaugural Music City Rodeo in Nashville brings together the rich history of rodeo music with the city's unparalleled musical heritage, creating a unique cultural event.",
      icon: <FaHorse />,
    },
  ];

  return (
    <div className="bg-white min-h-screen p-8 font-sans">
      <motion.h1 
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="text-5xl font-bold mb-8 text-center text-gray-800"
      >
        The Evolution of Music in Rodeo Culture
      </motion.h1>

      <div className="max-w-4xl mx-auto mb-12">
        <p className="text-lg mb-4 text-gray-700 leading-relaxed">
          Music, particularly country music from Nashville, has been an integral part of rodeo culture for decades. The relationship between rodeos and live music has not only enhanced the spectator experience but also significantly boosted ticket sales and expanded the reach of both industries.
        </p>
      </div>

      <section className="mb-12">
        <h2 className="text-3xl font-bold mb-4 text-gray-800">Live Music and the Houston Rodeo</h2>
        <p className="text-lg mb-4 text-gray-700 leading-relaxed">
          The Houston Rodeo, one of the largest rodeo events in the world, played a pivotal role in integrating live music performances into rodeo culture. In 1942, Gene Autry became the first musical act to perform at the Houston Rodeo, setting a precedent that would transform rodeo events across the country.
        </p>
        <p className="text-lg mb-4 text-gray-700 leading-relaxed">
          The landmark performance by Elvis Presley at the Houston Rodeo in 1956 marked a turning point, drawing unprecedented crowds and cementing the relationship between popular music and rodeo events.
        </p>
      </section>

      <section className="mb-12">
        <h2 className="text-3xl font-bold mb-4 text-gray-800">Impact on Ticket Sales</h2>
        <p className="text-lg mb-4 text-gray-700 leading-relaxed">
          The inclusion of live music performances, particularly by well-known country artists, has had a significant impact on rodeo ticket sales. Many rodeos have reported increases of up to 30% in attendance when featuring live concerts compared to non-concert events. This boost in ticket sales has not only increased revenue but also expanded the audience for rodeo events, attracting music fans who might not otherwise attend a rodeo.
        </p>
      </section>

      <section className="mb-12">
        <h2 className="text-3xl font-bold mb-4 text-gray-800">Nashville's Influence on Rodeo Music</h2>
        <p className="text-lg mb-4 text-gray-700 leading-relaxed">
          Nashville, known as the heart of country music, has played a crucial role in shaping the soundtrack of rodeo events since the early days of the sport. As the home of the Grand Ole Opry and numerous record labels, Nashville has been exporting country music to rodeos across the nation for decades.
        </p>
        <p className="text-lg mb-4 text-gray-700 leading-relaxed">
          The city's influence on rodeo music culture grew stronger in the 1960s and 1970s with the rise of outlaw country and traditional country revivals. Nashville-based artists became staples at rodeo events, further solidifying the connection between country music and rodeo culture.
        </p>
      </section>

      <h2 className="text-3xl font-bold mb-4 text-center text-gray-800">Timeline of Music in Rodeo Culture</h2>
      <VerticalTimeline>
        {timelineEvents.map((event, index) => (
          <VerticalTimelineElement
            key={index}
            className="vertical-timeline-element--work"
            contentStyle={{ background: '#f3f4f6', color: '#1f2937', boxShadow: '0 3px 0 #4b5563' }}
            contentArrowStyle={{ borderRight: '7px solid #f3f4f6' }}
            date={event.year}
            iconStyle={{ background: '#3b82f6', color: '#fff' }}
            icon={event.icon}
          >
            <h3 className="vertical-timeline-element-title text-xl font-semibold mb-2">{event.title}</h3>
            <p className="text-gray-600">{event.description}</p>
          </VerticalTimelineElement>
        ))}
      </VerticalTimeline>

      <div className="max-w-4xl mx-auto mt-12 bg-gray-50 p-8 rounded-lg shadow-md">
        <h2 className="text-3xl font-bold mb-4 text-gray-800">The Future of Music in Rodeo Culture</h2>
        <p className="text-lg mb-4 text-gray-700 leading-relaxed">
          As we launch the Music City Rodeo, we're celebrating the rich history of music in rodeo while also looking to the future. By hosting a world-class rodeo event in Nashville, we're creating a unique experience that honors the traditional sounds of rodeo while embracing the city's diverse musical landscape.
        </p>
        <p className="text-lg text-gray-700 leading-relaxed">
          This event promises to be a perfect blend of rodeo's musical heritage and Nashville's creative spirit, offering attendees an unforgettable experience that showcases the best of both worlds. It represents the next chapter in the long-standing relationship between rodeo culture and live music performance, continuing a tradition that has entertained and inspired audiences for generations.
        </p>
      </div>
    </div>
  );
};

export default RodeoAndMusicPage;
