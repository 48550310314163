import React, { useState, useMemo } from 'react';
import { useTable, Column } from 'react-table';
import { Chart } from "react-google-charts";
import { initialTasks, Task } from '../../data/timelineData';

const ProjectTimeline: React.FC = () => {
  const [tasks, setTasks] = useState<Task[]>(initialTasks);
  const [filter, setFilter] = useState<string>('All');

  const data = useMemo(() => {
    return tasks.filter(task => filter === 'All' || task.taskCategory === filter);
  }, [tasks, filter]);

  const columns = useMemo<Column<Task>[]>(
    () => [
      { Header: 'Date Range', accessor: 'dateRange' },
      { Header: 'Task Category', accessor: 'taskCategory' },
      { Header: 'Task Description', accessor: 'taskDescription' },
      { Header: 'Status', accessor: 'status' },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable<Task>({ columns, data });

  const handleAddTask = () => {
    const newTask: Task = {
      dateRange: 'New Date',
      taskCategory: 'New Category',
      taskDescription: 'New Task Description',
      ticketsAvailable: 'N/A',
      promotionalPush: 'N/A',
      dynamicPricing: 'N/A',
      status: 'Not Started',
    };
    setTasks([...tasks, newTask]);
  };

  const uniqueCategories = Array.from(new Set(tasks.map(task => task.taskCategory)));

  // Prepare data for Gantt chart
  const ganttData = [
    [
      { type: 'string', label: 'Task ID' },
      { type: 'string', label: 'Task Name' },
      { type: 'string', label: 'Resource' },
      { type: 'date', label: 'Start Date' },
      { type: 'date', label: 'End Date' },
      { type: 'number', label: 'Duration' },
      { type: 'number', label: 'Percent Complete' },
      { type: 'string', label: 'Dependencies' },
    ],
    ...tasks.map((task, index) => {
      const [startDate, endDate] = task.dateRange.split(' - ').map(date => new Date(date));
      return [
        index.toString(),
        task.taskDescription,
        task.taskCategory,
        startDate,
        endDate,
        null,
        task.status === 'Completed' ? 100 : task.status === 'In Progress' ? 50 : 0,
        null,
      ];
    }),
  ];

  return (
    <div className="project-timeline">
      <h1 className="text-3xl font-bold mb-4">Project Timeline Overview</h1>
      <div className="mb-4">
        <label htmlFor="filter" className="mr-2">Filter by Task Category: </label>
        <select 
          id="filter" 
          value={filter} 
          onChange={(e) => setFilter(e.target.value)}
          className="border rounded p-2"
        >
          <option value="All">All</option>
          {uniqueCategories.map(category => (
            <option key={category} value={category}>{category}</option>
          ))}
        </select>
        <button 
          onClick={handleAddTask}
          className="ml-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Add Task
        </button>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Gantt Chart</h2>
        <Chart
          width={'100%'}
          height={'400px'}
          chartType="Gantt"
          loader={<div>Loading Chart</div>}
          data={ganttData}
          options={{
            height: 400,
            gantt: {
              trackHeight: 30,
            },
          }}
          rootProps={{ 'data-testid': '1' }}
        />
      </div>

      <div className="overflow-x-auto">
        <h2 className="text-2xl font-bold mb-4">Task Table</h2>
        <table {...getTableProps()} className="min-w-full bg-white border border-gray-300">
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()} className="bg-gray-100">
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()} className="py-2 px-4 border-b text-left">
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="hover:bg-gray-50">
                  {row.cells.map(cell => (
                    <td {...cell.getCellProps()} className="py-2 px-4 border-b">
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProjectTimeline;
