import React from 'react';
import { TimelineNodeProps } from '../../types';

const TimelineNode: React.FC<TimelineNodeProps> = ({ title, description, icon: Icon }) => {
  return (
    <div className="flex items-center mb-4">
      <div className="bg-blue-500 rounded-full p-2 mr-4">
        <Icon className="text-white" size={24} />
      </div>
      <div>
        <h3 className="text-lg font-semibold">{title}</h3>
        <p className="text-gray-600">{description}</p>
      </div>
    </div>
  );
};

export default TimelineNode;