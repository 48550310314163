import React from 'react';
import TimelineNode from '../../components/Marketing/TimelineNode';
import { marketingData } from '../../data/marketingData';
import { MonthData } from '../../types';
import { FaCalendar } from 'react-icons/fa';

const MarketingPlan: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">Marketing Plan</h1>
      <div className="space-y-8">
        {marketingData.map((month: MonthData, index: number) => (
          <TimelineNode
            key={month.month}
            {...month}
            isEventMonth={index === marketingData.length - 1}
            title={month.month}
            description={`Goal: ${month.goal}`}
            icon={FaCalendar}
          />
        ))}
      </div>
    </div>
  );
};

export default MarketingPlan;