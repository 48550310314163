import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Home from './components/Home';
import { FaBars } from 'react-icons/fa';

// eventOverview imports
import EventOverview from './components/eventOverview/EventOverview';
import Vision from './components/eventOverview/Vision';
import GrowthPlan from './components/eventOverview/GrowthPlan';
import RodeoAndMusic from './components/eventOverview/RodeoAndMusic';
import RodeoHistory from './components/eventOverview/RodeoHistory';
import AboutRodeo from './components/eventOverview/AboutRodeo';
import AboutNashville from './components/eventOverview/AboutNashville';

// Audience Insights imports
import AudienceOverview from './components/audienceInsights/AudienceOverview';
import Demographics from './components/audienceInsights/Demographics';
import BehaviorEngagement from './components/audienceInsights/BehaviorEngagement';
import Psychographics from './components/audienceInsights/Psychographics';
import Personas from './components/audienceInsights/Personas';
import PropensityModel from './components/audienceInsights/PropensityModel';
import AudienceStrategy from './components/audienceInsights/AudienceStrategy';

// Event Planning imports
import MarketingPlan from './pages/EventPlanning/MarketingPlan';
import SponsorshipStrategy from './pages/EventPlanning/SponsorshipStrategy';
import Partnerships from './pages/EventPlanning/Partnerships';
import Vendors from './pages/EventPlanning/Vendors';
import ComprehensiveTicketingStrategy from './components/eventPlanning/ComprehensiveTicketingStrategy';
import ProjectTimeline from './components/eventPlanning/ProjectTimeline';

// Event Logistics imports
import RunOfShow from './components/eventLogistics/RunOfShow';
import ProductionOverview from './components/eventLogistics/ProductionOverview';
import VIPExperiences from './components/eventLogistics/VIPExperiences';
import AnimalWelfare from './components/eventLogistics/AnimalWelfare';
import SecurityEmergencyPlanning from './components/eventLogistics/SecurityEmergencyPlanning';
import CateringHospitality from './components/eventLogistics/CateringHospitality';

// Financial imports
import CompetitiveAnalysis from './components/financials/CompetitiveAnalysis';

import './App.css';

function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth < 768;
      setIsMobile(mobile);
      setIsSidebarOpen(!mobile);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <Router>
      <div className="app-container bg-lavender min-h-screen flex">
        <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} isMobile={isMobile} />
        <div className={`flex-1 transition-all duration-300 ${isSidebarOpen && !isMobile ? 'ml-64' : ''}`}>
          <div className="p-8">
            {!isSidebarOpen && (
              <button
                className="fixed top-4 left-4 z-50 p-2 rounded-md bg-indigo-600 text-white"
                onClick={toggleSidebar}
              >
                <FaBars size="1.5em" />
              </button>
            )}
            <div className="bg-white rounded-3xl shadow-lg p-8 min-h-full">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/event-overview" element={<EventOverview />} />
                <Route path="/vision" element={<Vision />} />
                <Route path="/growth-plan" element={<GrowthPlan />} />
                <Route path="/rodeo-and-music" element={<RodeoAndMusic />} />
                <Route path="/rodeo-history" element={<RodeoHistory />} />
                <Route path="/about-rodeo" element={<AboutRodeo />} />
                <Route path="/about-nashville" element={<AboutNashville />} />
                <Route path="/audience-insights" element={<AudienceOverview />} />
                <Route path="/demographics" element={<Demographics />} />
                <Route path="/behavior-engagement" element={<BehaviorEngagement />} />
                <Route path="/psychographics" element={<Psychographics />} />
                <Route path="/personas" element={<Personas />} />
                <Route path="/propensity-model" element={<PropensityModel />} />
                <Route path="/audience-strategy" element={<AudienceStrategy />} />
                <Route path="/marketing-plan" element={<MarketingPlan />} />
                <Route path="/sponsorship-strategy" element={<SponsorshipStrategy />} />
                <Route path="/partnerships" element={<Partnerships />} />
                <Route path="/vendors" element={<Vendors />} />
                <Route path="/ticketing-strategy" element={<ComprehensiveTicketingStrategy />} />
                <Route path="/project-timeline" element={<ProjectTimeline />} />
                <Route path="/run-of-show" element={<RunOfShow />} />
                <Route path="/production-overview" element={<ProductionOverview />} />
                <Route path="/vip-experiences" element={<VIPExperiences />} />
                <Route path="/animal-welfare" element={<AnimalWelfare />} />
                <Route path="/security-emergency-planning" element={<SecurityEmergencyPlanning />} />
                <Route path="/catering-hospitality" element={<CateringHospitality />} />
                <Route path="/competitive-analysis" element={<CompetitiveAnalysis />} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;