import React from 'react';
import { motion } from 'framer-motion';
import { Line } from 'react-chartjs-2';
import { FaMusic, FaHorse, FaUserFriends, FaMapMarkedAlt, FaGraduationCap } from 'react-icons/fa';
import { ChartOptions, ChartData } from 'chart.js';
import 'chart.js/auto';

const attendanceData: ChartData<'line'> = {
  labels: ['2025', '2026', '2027', '2028'],
  datasets: [
    {
      label: 'Projected Attendance',
      data: [30000, 40000, 50000, 60000],
      fill: false,
      backgroundColor: '#4C51BF',
      borderColor: '#5A67D8',
    },
  ],
};

const chartOptions: ChartOptions<'line'> = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
    },
  },
  animation: {
    duration: 1000,
    easing: 'easeOutQuart',
  },
};

const Vision: React.FC = () => {
  return (
    <div className="container mx-auto p-8 bg-white">
      <motion.h1 
        className="text-4xl font-bold mb-8 text-center"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        Vision and Long-Term Objectives
      </motion.h1>

      <motion.p 
        className="text-xl mb-8 text-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.2, duration: 0.5 }}
      >
        The Music City Rodeo is more than just an event; it's a cultural celebration that brings the traditions of rodeo together with Nashville's world-class music scene. Over the next few years, this event will establish itself as a key annual tradition, growing in size and influence, both in Nashville and throughout the Southeast.
      </motion.p>

      <motion.div 
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.4, duration: 0.5 }}
      >
        <div className="bg-blue-100 p-6 rounded-lg shadow-lg">
          <FaHorse className="text-4xl mb-4 text-blue-600" />
          <h2 className="text-2xl font-bold mb-4">Build an Annual Tradition</h2>
          <p>Create a must-attend event that draws both rodeo enthusiasts and music lovers.</p>
          <ul className="list-disc list-inside mt-4">
            <li>2025 Target: 30,000 attendees</li>
            <li>2028 Target: 60,000+ attendees</li>
          </ul>
        </div>

        <div className="bg-green-100 p-6 rounded-lg shadow-lg">
          <FaMusic className="text-4xl mb-4 text-green-600" />
          <h2 className="text-2xl font-bold mb-4">Unite Rodeo and Music</h2>
          <p>Blend rodeo and country music cultures for a unique, exciting experience.</p>
          <ul className="list-disc list-inside mt-4">
            <li>2025: Headliner country music performances</li>
            <li>2026-2028: Cross-genre performances</li>
          </ul>
        </div>

        <div className="bg-yellow-100 p-6 rounded-lg shadow-lg">
          <FaUserFriends className="text-4xl mb-4 text-yellow-600" />
          <h2 className="text-2xl font-bold mb-4">Phased Growth</h2>
          <p>Expand the event while maintaining a high-quality experience.</p>
          <ul className="list-disc list-inside mt-4">
            <li>2025: 3-day inaugural event</li>
            <li>2026: Additional stages and activities</li>
            <li>2028: Multi-week festival experience</li>
          </ul>
        </div>
      </motion.div>

      <motion.div 
        className="mb-16"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.6, duration: 0.5 }}
      >
        <h2 className="text-3xl font-bold mb-4">Projected Attendance Growth</h2>
        <Line data={attendanceData} options={chartOptions} />
      </motion.div>

      <motion.div 
        className="mb-16"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.8, duration: 0.5 }}
      >
        <h2 className="text-3xl font-bold mb-4">Expand to New Markets</h2>
        <p className="mb-4">We aim to grow into Southeastern markets where rodeo is underrepresented, including:</p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="bg-purple-100 p-4 rounded-lg">
            <h3 className="font-bold mb-2">Charlotte, NC</h3>
            <p>First-year attendance: 25,000</p>
            <p>Year three projection: 35,000</p>
          </div>
          <div className="bg-pink-100 p-4 rounded-lg">
            <h3 className="font-bold mb-2">Birmingham, AL</h3>
            <p>First-year attendance: 22,000</p>
            <p>Annual growth: 12%</p>
          </div>
          <div className="bg-indigo-100 p-4 rounded-lg">
            <h3 className="font-bold mb-2">New Orleans, LA</h3>
            <p>First-year attendance: 28,000</p>
            <p>Potential for local culture integration</p>
          </div>
        </div>
      </motion.div>

      <motion.div 
        className="mb-16"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 1, duration: 0.5 }}
      >
        <h2 className="text-3xl font-bold mb-4">Community Engagement and Education</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <FaGraduationCap className="text-4xl mb-4 text-red-600" />
            <h3 className="text-2xl font-bold mb-2">Educational Outreach</h3>
            <p>Partner with local schools to offer programs highlighting rodeo history and its connections to American culture.</p>
          </div>
          <div>
            <FaMapMarkedAlt className="text-4xl mb-4 text-teal-600" />
            <h3 className="text-2xl font-bold mb-2">501(c)(3) Initiatives</h3>
            <ul className="list-disc list-inside">
              <li>Rodeo scholarships for students</li>
              <li>Community outreach programs</li>
            </ul>
          </div>
        </div>
      </motion.div>

      <motion.div 
        className="mb-16"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 1.2, duration: 0.5 }}
      >
        <h2 className="text-3xl font-bold mb-4">Nashville's Growth as a Cultural Epicenter</h2>
        <blockquote className="italic text-xl mb-4">
          "Nashville is about more than just country music. It's a city ready for something new, and rodeo is the perfect fit." — Garth Brooks
        </blockquote>
        <p>
          As Nashville evolves into a broader entertainment hub, the Music City Rodeo will play a crucial role in attracting new visitors and expanding the city's cultural offerings.
        </p>
      </motion.div>

      <motion.div 
        className="bg-gray-100 p-6 rounded-lg shadow-lg"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 1.4, duration: 0.5 }}
      >
        <h2 className="text-3xl font-bold mb-4">Conclusion</h2>
        <p className="mb-4">
          The Music City Rodeo is set to create a new chapter in Nashville's entertainment story. With a vision to unite rodeo sports and live music, expand into new markets, and engage deeply with the community, this event will become a defining feature of the city's cultural future.
        </p>
        <h3 className="text-2xl font-bold mb-2">Key Takeaways:</h3>
        <ul className="list-disc list-inside">
          <li>Focus on building an annual event synonymous with Nashville's identity</li>
          <li>Phased growth to scale the event into a multi-week festival by 2028</li>
          <li>Expansion into untapped Southeastern markets</li>
          <li>Deep community and educational engagement through 501(c)(3) initiatives</li>
          <li>A long-term vision aligning with Nashville's growth as a cultural and entertainment hub</li>
        </ul>
      </motion.div>
    </div>
  );
};

export default Vision;
