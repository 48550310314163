// src/data/marketingData.ts

import { MonthData, Channel } from '../types';

export const marketingData: MonthData[] = [
  {
    month: 'November 2024',
    activities: [
      {
        channel: 'Digital' as Channel,
        activities: [
          'Tease Music City Rodeo playlists and YouTube content featuring behind-the-scenes footage of cowboys and artists',
          'Launch the Music City Rodeo website to collect emails for early access to presales',
          'Promote upcoming ticket presales in December through Spotify and YouTube ads'
        ]
      },
      {
        channel: 'Radio' as Channel,
        activities: [
          'Run teaser ads highlighting upcoming artist and cowboy interviews about their rodeo involvement'
        ]
      },
      {
        channel: 'OOH' as Channel,
        activities: [
          'Start with teaser billboard ads in Nashville and surrounding cities featuring artist and cowboy silhouettes, with a "coming soon" message for ticket presales',
          'Promote upcoming ticket presales in December through OOH ads'
        ]
      },
      {
        channel: 'Sponsorship' as Channel,
        activities: [
          'Finalize negotiations with major sponsors like Wrangler and Ford, preparing for the sponsor rollout during the December event'
        ]
      }
    ],
    totalImpressions: 0,
    goal: 'Pre-Launch: Teasers, Announcements, and Data Capture Build-Up'
  },
  // Add more months here following the same structure...
];