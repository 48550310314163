import React from 'react';

const Vendors: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Vendors</h1>
      <p className="mb-4">
        Our carefully selected vendors play a vital role in creating the vibrant 
        atmosphere of the Music City Rodeo. From food and beverage to merchandise 
        and attractions, our vendors contribute to the overall guest experience.
      </p>
      {/* Add more content here */}
    </div>
  );
};

export default Vendors;